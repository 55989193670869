import {
  Button,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import DetailsText from "components/Inputs/DetailsText";
import { Form, FormikProvider, useFormik } from "formik";
import useAuth from "hooks/useAuth";
import { useState } from "react";
import * as Yup from "yup";
import agent from "../../api/agent";
import { INoteRowInfo } from "./NotesList";

interface IAddNoteProps {
  patientEngagementId: string;
  onCreate: () => void;
}

const useStyles = makeStyles(() => ({
  title: {
    marginLeft: 8,
    fontSize: "14px",
    color: "#212b36",
  },
}));

export default function AddNote({
  patientEngagementId,
  onCreate,
}: IAddNoteProps) {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [showInput, setShowInput] = useState(true);
  const { user } = useAuth();

  const classes = useStyles();

  function handleOpenDialog() {
    setOpenDialog(true);
  }

  function handleCloseDialog() {
    setOpenDialog(false);
  }

  const formSchema = Yup.object().shape({
    text: Yup.string().required("Text is required"),
  });

  const formik = useFormik({
    children: undefined,
    component: undefined,
    initialErrors: undefined,
    initialTouched: undefined,
    initialValues: {
      text: undefined,
    },
    innerRef: undefined,
    isInitialValid: undefined,
    validationSchema: formSchema,
    onSubmit: async (values) => {
      try {
        const newNote: INoteRowInfo = {
          parentId: parseInt(patientEngagementId),
          text: values.text,
          type: "MemberEngagement",
          author: "", // TODO: get user
        };

        const response = await agent.PatientEngagement.postNote(newNote);
        onCreate();
        handleCloseDialog();
      } catch (error) {
        console.log(error);
      }
    },
  });

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setValues,
    isValid,
  } = formik;

  return (
    <>
      <Grid item container justifyContent="flex-start">
        <Button variant="contained" onClick={() => handleOpenDialog()}>
          Add Note
        </Button>
      </Grid>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">Note</DialogTitle>
        <DialogContent>
          <CardContent>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Grid container direction="column" spacing={3}>
                  <Grid item md={12} container direction="row">
                    <Typography
                      variant="h5"
                      component="h5"
                      paragraph
                      className={classes.title}
                    >
                      Add note
                    </Typography>
                  </Grid>

                  <Grid item md={12} container spacing={3}>
                    <Grid item md={12}>
                      <DetailsText
                        title="Text"
                        getFieldProps={getFieldProps("text")}
                        showInput={showInput}
                        error={Boolean(touched.text && errors.text)}
                        helperText={touched.text && errors.text}
                      />
                    </Grid>
                  </Grid>

                  <Grid item md={12} container direction="row">
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      type="submit"
                      disabled={!isValid || isSubmitting}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </FormikProvider>
          </CardContent>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
}
