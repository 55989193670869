import {
  Button,
  Checkbox,
  Divider,
  Drawer,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  Stack,
  TextField,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { urlBackNestApp } from "routes/urls";
import * as Yup from "yup";

interface ConfirmNatOptInProps {
  isOpen: boolean;
  handlerIsOpen: Function;
  checklistHandler?: any;
  openDispositon: any;
  addPatientEngagementChecklistItem: Function;
}

export default function ConfirmNatOptIn({
  isOpen,
  handlerIsOpen,
  openDispositon,
  checklistHandler,
  addPatientEngagementChecklistItem,
}: ConfirmNatOptInProps) {

  const [optInMethodCatalog, setOptInMethodCatalog] = useState([]);
  const [optInDecisionCatalog, setOptInDecisionCatalog] = useState([]);

  const confirmNatOptInSchema = Yup.object().shape({
    opt_in_decision: Yup.string().required("Opt-Out Decision is required"),
    method: Yup.string().required("Method is required"),
    notes: Yup.string(),
    dispose: Yup.boolean(),
  });

  const initialValues = {
    opt_in_decision: "",
    method: "",
    notes: "",
    dispose: false,
  };

  const handleSubmit = async (values: any) => {

    const valuesToSubmit = { ...values };
    delete valuesToSubmit.dispose;

    try {
      addPatientEngagementChecklistItem(
        "CONFIRM_NAT_OPTIN_ITEM",
        "Confirmed HJ Opt-Out",
        valuesToSubmit,
        false,
        values.dispose
      );
      handlerIsOpen(false);
      if (checklistHandler) checklistHandler();
      if(values?.opt_in_decision === "Opt-out of InCK Services"){
        openDispositon(true);
      }
    } catch (error) {
      console.log("failed confirming HJ Opt-Out");
    }
  };

  const getOptInMethods = async () => {
    const optInMethodCode = "OPT_IN_METHODS";
    axios
      .get(`${urlBackNestApp}/catalog/${optInMethodCode}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("encryptUser")}`,
        },
      })
      .then((response) => {
        setOptInMethodCatalog(response.data);
      })
      .catch((reason) => {
        console.log(reason);
      });
  };

  const getOptInDecisions = async () => {
    const optInDecisionCode = "OPT_IN_DECISIONS";
    axios
      .get(`${urlBackNestApp}/catalog/${optInDecisionCode}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("encryptUser")}`,
        },
      })
      .then((response) => {
        setOptInDecisionCatalog(response.data);
      })
      .catch((reason) => {
        console.log(reason);
      });
  };

  useEffect(() => {
    getOptInMethods();
    getOptInDecisions();
  }, []);

  return (
    <Drawer
      anchor="left"
      open={isOpen}
      onClose={() => {
        handlerIsOpen(false);
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={confirmNatOptInSchema}
        onSubmit={handleSubmit}
      >
        {(props: any) => {
          return (
            <Form id="confirmNatOptInForm" autoComplete="off">
              <List component="nav">
                <ListItem>
                  <Grid container justifyContent="space-between">
                    <Stack direction="row" spacing={2}>
                      <Button
                        variant="contained"
                        type="submit"
                        disabled={props.isSubmitting}
                      >
                        Confirm HJ Opt-Out
                      </Button>
                      <Button
                        onClick={() => {
                          handlerIsOpen(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Stack>
                  </Grid>
                </ListItem>
                <Divider />
                <ListItem>
                  <Grid container direction="column" marginTop={2} width={800}>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      minHeight={60}
                    >
                      <Grid container item xs={4}>
                        <Typography>Opt-Out Decision</Typography>
                      </Grid>

                      <Grid container item xs={8}>
                        <TextField
                          id="opt_in_decision"
                          fullWidth
                          placeholder="Select Opt-Out Decision"
                          SelectProps={{
                            native: true,
                          }}
                          {...props.getFieldProps("opt_in_decision")}
                          error={Boolean(
                            props.touched.opt_in_decision &&
                              props.errors.opt_in_decision
                          )}
                          helperText={
                            props.touched.opt_in_decision &&
                            props.errors.opt_in_decision
                          }
                          select
                        >
                          <option value="" disabled></option>
                          {optInDecisionCatalog.map((decision: any) => (
                            <option value={decision.shortDisplayName}>
                              {decision.shortDisplayName}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      minHeight={60}
                    >
                      <Grid container item xs={4}>
                        <Typography>Method</Typography>
                      </Grid>

                      <Grid container item xs={8}>
                        <TextField
                          id="method"
                          fullWidth
                          placeholder="Select Method"
                          SelectProps={{
                            native: true,
                          }}
                          {...props.getFieldProps("method")}
                          error={Boolean(
                            props.touched.method && props.errors.method
                          )}
                          helperText={
                            props.touched.method && props.errors.method
                          }
                          select
                        >
                          <option value="" disabled></option>
                          {optInMethodCatalog.map((method: any) => (
                            <option value={method.shortDisplayName}>
                              {method.shortDisplayName}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>

                    {props.values.opt_in_decision === "Deny" && (
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        minHeight={60}
                      >
                        <Grid container item xs={4}>
                          <Typography>Close engagement box</Typography>
                        </Grid>

                        <Grid container item xs={8}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                {...props.getFieldProps("dispose")}
                                checked={props.values.dispose}
                              />
                            }
                            label=""
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
              </List>
            </Form>
          );
        }}
      </Formik>
    </Drawer>
  );
}
