import { Grid } from "@material-ui/core";
import KeyValueGrid from "components/KeyValueGrid";
import KeyValuePaper from "components/KeyValuePaper";
import useAuth from "hooks/useAuth";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import agent from "../../api/agent";
import AddNote from "./AddNote";

interface INotesList {
  patientEngagementId: string;
}

export interface INoteRowInfo {
  id?: number;
  parentId?: number;
  text?: string;
  type?: string;
  author?: string;
  timestamp?: string;
}

export default function NotesList({ patientEngagementId }: INotesList) {
  const { user } = useAuth();
  const [notesRowInfoList, setNotesRowInfoList] = useState<INoteRowInfo[]>();
  const { t } = useTranslation();

  useEffect(() => {
    if (patientEngagementId) {
      getNotes(patientEngagementId);
    }
  }, [patientEngagementId]);

  const getNotes = async (patientEngagementId: string) => {
    const notesList = await agent.PatientEngagement.getNotes(
      patientEngagementId
    );

    setNotesRowInfoList(notesList);
  };

  const getRowInfo = (rowInfo: INoteRowInfo) => {
    return (
      <Grid container item md={12} key={rowInfo.id} onClick={() => {}}>
        <KeyValuePaper>
          <KeyValueGrid label="Text" value={rowInfo.text || ""} />
          <KeyValueGrid label="Type" value={rowInfo.type || ""} />
          <KeyValueGrid label="Author" value={rowInfo.author || ""} />
          <KeyValueGrid label="Date" value={rowInfo.timestamp ? moment(rowInfo.timestamp).local().format("MM-DD-YYYY HH:mm") : ""} />
        </KeyValuePaper>
      </Grid>
    );
  };

  return (
    <>
      <KeyValuePaper title={t(`patient_engagement.complementary_info.notes`)}>
        <Grid container spacing={3} direction="row">
          <Grid
            item
            md={12}
            width="100%"
            container
            flexDirection="row"
            spacing={2}
            className="request-details-container"
          >
            {patientEngagementId && (
              <AddNote
                patientEngagementId={patientEngagementId}
                onCreate={() => {
                  getNotes(patientEngagementId);
                }}
              />
            )}
            {notesRowInfoList &&
              notesRowInfoList.length > 0 &&
              notesRowInfoList.map((item: INoteRowInfo) => {
                return getRowInfo(item);
              })}
          </Grid>
        </Grid>
      </KeyValuePaper>
    </>
  );
}
