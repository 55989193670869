import React from "react";
import { CheckCircleOutline as CheckIcon } from "@material-ui/icons";
import { Grid, Button, ButtonProps } from "@material-ui/core";
import { styled } from "@material-ui/styles";

export default (item: any, componentMapping: any, workflowHandler: any) => {
  const parseMetadata = (item?.metadata && JSON.parse(item?.metadata)) || "";
  const props = parseMetadata?.ui?.component;
  const elementName = props?.name || item?.name;
  const itemCode = item?.code;
  const metas = item?.isValid && JSON.parse(item?.isValid);
  const itemIsValid = metas ? metas?.response?.isValid : false;

  const {
    patient = {},
    checklistItem = {},
    refreshWorkFlow: refreshChecklistHandler = () => {},
    refreshRecordHandler = () => {},
    more = {}
  } = workflowHandler?.[itemCode]?.["others"];

  if (props?.type !== undefined) {
    return !itemIsValid ? (
      React.createElement(componentMapping[props?.type], {
        key: props?.id,
        elementName,
        ...props,
        others: {
          ...workflowHandler?.[itemCode],
          patient,
          checklistItem,
          refreshChecklistHandler,
          refreshRecordHandler,
        },
      })
    ) : (
      <Grid container direction="row" alignItems="center">
        <Grid item>
          <CheckIcon fontSize="large" sx={{ color: "green", marginRight: '10px' }} />
        </Grid>
        <Grid item>
          <span style={{ fontSize: 12 }}>{metas?.response?.message || elementName}</span>
        </Grid>
      </Grid>
    );
  }

  const ItemButton = styled(Button)<ButtonProps>(() => ({
    color: "#fff",
    minWidth: "190px",
    backgroundColor: "#1890FF",
    "&:hover": {
      backgroundColor: "#1890FF",
    },
  }));

  return !itemIsValid ? (
    <div
      className={`btn-${elementName.toLowerCase()}`}
      style={{ marginBottom: 10 }}
    >
      <ItemButton
        variant="contained"
        onClick={
          workflowHandler?.[itemCode]
            ? () => workflowHandler?.[itemCode]?.["handler"]({ record: patient, checklistItem, refreshChecklistHandler, refreshRecordHandler, more })
            : () => {}
        }
      >
        {elementName}
      </ItemButton>
    </div>
  ) : (
    <Grid container direction="row" alignItems="center">
      <Grid item>
        <CheckIcon fontSize="small" sx={{ color: "green" }} />
      </Grid>
      <Grid item>
        <span style={{ fontSize: 12 }}>{metas?.response?.message}</span>
      </Grid>
    </Grid>
  );
};
