import React, { useRef, useState, useEffect } from 'react';
import { Typography, Divider, Box, List, ListItem, ListItemText, Badge, IconButton } from '@material-ui/core';
import Scrollbar from '../../components/Scrollbar';
import { MIconButton } from '../../components/@material-extend';
import MenuPopover from '../../components/MenuPopover';
import { NotificationsNone, Markunread } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { urlBackNestApp } from "routes/urls";
import useAuth from 'hooks/useAuth';


interface NotificationProps {
  id: number,
  title: string,
  description: string,
  path: string,
  isRead: boolean,
};

const UserNotifications = ({ notifications, fetchNotifications, sx }: any) => {
  const { user } = useAuth();
  const [isOpenNotification, setIsOpenNotification] = useState(false);
  const [list, setList] = useState(notifications);
  const anchorRef = useRef(null);

  useEffect(()=>{
    setList(notifications);
  }, [notifications]);

  const markAsReaded = (itemId: any) => {
    fetch(
      `${urlBackNestApp}/notifications/?user=${user?.userName}&itemId=${itemId}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("encryptUser")}`,
        },
      }
    ).then(() => fetchNotifications());
  };

  const handleOpenNotifications = () => setIsOpenNotification(true);
  const handleCloseNotifications = () => setIsOpenNotification(false);
  const isReadNotificationHandler = (id: any) => {
    const updatedList = list.map((i: any) => {
      if (i.id === id) {
        return {
          ...i,
          isRead: !i.isRead,
        };
      }

      return i;
    });

    setList(updatedList);
  };

  return (
    <>
      <MIconButton ref={anchorRef} onClick={handleOpenNotifications} sx={sx}>
        <Badge
          badgeContent={list.filter((i: any) => i.notifyTriggerId.isRead === false).length || null}
          color="error"
        >
          <NotificationsNone />
        </Badge>
      </MIconButton>

      <MenuPopover
        open={isOpenNotification}
        onClose={handleCloseNotifications}
        anchorEl={anchorRef.current}
        sx={{ width: 400 }}
      >
        <Box sx={{ alignItems: "center", py: 2, px: 2.5, display: "flex" }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              You have {list.filter((i: any) => i.notifyTriggerId.isRead === false).length || 0}{" "}
              unread notifications
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Scrollbar>
          <List disablePadding>
            {list.map((item: any, index: any) => {
              return (
                <>
                  <ListItem
                    key={item.id || index}
                    sx={{
                      ...(!item.notifyTriggerId.isRead && {
                        bgcolor: "action.selected",
                      }),
                    }}
                    {...(item.path && {
                      to: item.path,
                      component: Link,
                      button: true,
                    })}
                    // to={item.path||'#'}
                    // button
                    // component={Link}
                  >
                    <ListItemText
                      primary={
                        <Typography
                          variant="subtitle2"
                          sx={{ display: "flex" }}
                        >
                          <span style={{ marginRight: "5px" }}>
                            {item.notifyTriggerId.name}
                          </span>{" "}
                          <Typography
                            component="span"
                            variant="body2"
                            style={{
                              marginRight: "20px",
                              fontSize: "13px",
                              color: "#637381",
                            }}
                          >
                            {item.notifyTriggerId.desc}{" "}
                          </Typography>
                          <Typography component="span">
                            {!item.notifyTriggerId.isRead && (
                              <>
                                <IconButton
                                  onClick={() =>
                                    markAsReaded(item.notifyTriggerId.id)
                                  }
                                  sx={{
                                    position: "absolute",
                                    right: "20px",
                                    top: 0,
                                  }}
                                >
                                  <Markunread
                                    sx={{
                                      width: "14px",
                                      height: "14px",
                                    }}
                                  />
                                </IconButton>
                              </>
                            )}
                          </Typography>
                        </Typography>
                      }
                    ></ListItemText>
                  </ListItem>
                  <Divider />
                </>
              );
            })}
          </List>
        </Scrollbar>
      </MenuPopover>
    </>
  );
};

export default UserNotifications;