import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";
import { Form, FormikProvider, useFormik } from "formik";
import { OrderResponse } from "models/order";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { urlBackNestApp } from "routes/urls";
import { dateYearFormatWithAge } from "utils/datesFormats";
import * as Yup from "yup";
import { PATH_DASHBOARD } from "../../routes/paths";

export function DeleteModal({
  openmodaldelete,
  handleAllChanges,
  patient,
}: {
  openmodaldelete: any;
  handleAllChanges: any;
  patient: any;
}) {
  return (
    <Dialog
      open={openmodaldelete}
      onClose={() => handleAllChanges("closeDeletePatient")}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Patient delete"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          This Action cannot be undone, Delete <span>{patient?.name}</span>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleAllChanges("closeDeletePatient")}
          color="primary"
        >
          Close
        </Button>
        <Button
          onClick={() => handleAllChanges("deletePatient")}
          color="primary"
        >
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function CreateOrderModal({
  orderModalOpen,
  setOrderModalOpen,
  patient,
  handleAfterCreation,
  orderid,
  existingorder,
  snackerror,
  snackerrormsg,
  addPatientEngagementChecklistItem,
  checklist,
}: {
  orderModalOpen: any;
  setOrderModalOpen: any;
  patient: any;
  handleAfterCreation: (orderUuid: string) => void;
  orderid: any;
  existingorder: boolean;
  snackerror: any;
  snackerrormsg: any;
  addPatientEngagementChecklistItem: (...args: any) => void;
  checklist: any;
}) {
  const [patientContacts, setPatientContacts] = useState<any>([]);
  const [disableCreateRequest, setDisableCreateRequest] = useState<boolean>(
    true
  );
  const [guardianNotContact, setGuardianNotContact] = useState<boolean>(false);

  const navigate = useNavigate();

  const getContactPatients = async () => {
    const resp = await fetch(
      `${urlBackNestApp}/healthcare/patient/patient-contacts/${patient.id}`,
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("encryptUser")}`,
        },
      }
    );
    const json = await resp.json();
    setPatientContacts(json);
  };

  useEffect(() => {
    if (patient) {
      getContactPatients();
    }
    return () => {};
  }, [patient]);

  const generalInfoSchema = Yup.object().shape({
    isForPatient: Yup.string().required("It is for patient is required"),
    guardian: Yup.string().required("It is for patient is required"),
  });
  const formik = useFormik({
    initialValues: {
      isForPatient: "",
      guardian: "",
    },
    validationSchema: generalInfoSchema,
    onSubmit: async (values) => {
      let patientDetails = [];

      if (values.guardian !== "No Applicable") {
        const filterContact = patientContacts.filter((item: any) => {
          const full_name_middle = `${item.name.given[0]} ${item.name.family}`;
          const full_name = `${item.name.given[0]} ${item.name.family}`;
          return (
            values.guardian.includes(full_name) ||
            values.guardian.includes(full_name_middle)
          );
        });

        const contactPhone = filterContact?.[0]?.telecom?.find((item: any) => {
          if (item.system === undefined || item.value === undefined)
            return false;
          return item.system === "phone";
        })?.value;

        const contactEmail = filterContact?.[0]?.telecom?.find((item: any) => {
          if (item.system === undefined || item.value === undefined)
            return false;
          return item.system === "email";
        })?.value;

        patientDetails.push({
          firstName: filterContact[0].name.given[0],
          lastName: filterContact[0].name.family,
          email: contactEmail === undefined ? null : contactEmail,
          phone: contactPhone === undefined ? null : contactPhone,
        });
      }
      patientDetails.push(values.guardian);

      const data = {
        patientId: patient.id,
        patientContact: patientDetails,
        orderid: orderid,
        existingorder: existingorder,
      };
      fetch(`${urlBackNestApp}/healthcare/patient/userorg/getorg`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("encryptUser")}`,
        },
      })
        .then((userorg) => userorg.json())
        .then(async (userorg) => {
          const { name, fhirUri } = userorg;
          if (name !== "" && fhirUri !== "") {


            const orderRes = await createOrder(data);
            resetForm();
            setOrderModalOpen(false);
            handleAfterCreation(orderRes.uuid);
            addPatientEngagementChecklistItem(
              checklist?.code || "INITIATE_NAT_REQUEST_ITEM",
              "Initiate HJ Request confirmed",
              data,
              false,
              false
            );


            /*const preOrderResponse = await preCreateOrder(data);

            if (
              preOrderResponse?.hasOwnProperty("isEligible") &&
              preOrderResponse?.isEligible === false
            ) {
              snackerror(true);
              snackerrormsg(
                "This patient does not qualify for the program because it is over 20+"
              );
              resetForm();
              setOrderModalOpen(false);
            } else if (
              preOrderResponse?.hasOwnProperty("isEligible") &&
              preOrderResponse?.isEligible === true
            ) {
              const orderRes = await createOrder(data);
              resetForm();
              setOrderModalOpen(false);
              handleAfterCreation(orderRes.uuid);
              addPatientEngagementChecklistItem(data);
            }*/
          } else {
            resetForm();
            setOrderModalOpen(false);
            snackerror(true);
            snackerrormsg("Operation not allowed, missing organization");
            console.log("No permitida");
          }
        });
    },
  });

  const preCreateOrder = async (data: any): Promise<any> => {
    const response = await fetch(`${urlBackNestApp}/order/preCreateOrder`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("encryptUser")}`,
      },
      body: JSON.stringify(data),
    });

    return response.json();
  };

  const createOrder = async (data: any): Promise<OrderResponse> => {
    const response = await fetch(`${urlBackNestApp}/order/new`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("encryptUser")}`,
      },
      body: JSON.stringify(data),
    });

    return response.json();
  };

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    resetForm,
    values,
    setSubmitting,
  } = formik;


  useEffect(() => {

    let emailContact;

    if(values.isForPatient  === "Yes"){
      emailContact = (patient?.email?.[0]?.email || patient?.telecom_value?.[0]?.phone) ?? null;
    }else{
      const contact = patientContacts?.find((item) => `${item?.name?.given?.[0]} ${item?.name?.family}` === values.guardian) ?? null;
      emailContact = (contact?.telecom?.find((item) => item?.system === 'email')?.value || 
        contact?.telecom?.find((item) => item?.system === 'phone')?.value) ?? null;
    }

    if(!emailContact){
      setGuardianNotContact(true);
    }else{
      setGuardianNotContact(false);
    }
  }, [patient, values, patientContacts]);

  useEffect(() => {
    if (values.isForPatient === "Yes") {
      setFieldValue("guardian", "No Applicable");
      setSubmitting(false);
    } else if (patientContacts[0] === "No contacts") {
      setSubmitting(true);
    }

    if (
      values.guardian === "" ||
      values.isForPatient === "" ||
      (values.isForPatient === "Yes" &&
        ((patient.email === undefined && patient.phones === undefined) ||
          (patient.email !== undefined &&
            patient.email.length === 0 &&
            patient.phones !== undefined &&
            patient.phones.length === 0)))
    ) {
      setDisableCreateRequest(true);
    } else {
      setDisableCreateRequest(false);
    }
  }, [values, patient]);

  useEffect(() => {
    if (isSubmitting) {
      setDisableCreateRequest(true);
      setGuardianNotContact(false);
    }
  }, [isSubmitting]);

  const selectContactValues = () => {
    return patientContacts[0].name ? (
      patientContacts.map((item: any) => (
        <option value={item.name.given[0] + " " + item.name.family}>
          {item.name.given[0] + " " + item.name.family}
        </option>
      ))
    ) : (
      <option value="" disabled>
        Not Found
      </option>
    );
  };

  const handleEditEmail = (id: string) => {
    navigate(`${PATH_DASHBOARD.app.patients}/${id}?type=view`, {
      replace: false,
    });
  };

  return patient ? (
    <Dialog
      fullWidth
      open={orderModalOpen}
      onClose={() => setOrderModalOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <DialogTitle style={{ textAlign: "center" }}>
            <Typography key="3" variant="h4" color="text.primary">
              Create HJ Assessment
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Grid container direction="row" spacing={3}>
              <Grid item md={12} container>
                <Typography key="3" variant="h5" color="text.primary">
                  Member Details
                </Typography>
              </Grid>
              <Grid item md={6} container direction="column">
                <Typography key="3" color="text.primary">
                  <b>Name </b>
                </Typography>
                <Typography key="3" style={{ color: "#919eab" }}>
                  {patient.name.middle_name ? `${patient.name.given} ${patient.name.middle_name} ${patient.name.family}`:`${patient.name.given} ${patient.name.family}`}
                </Typography>
              </Grid>
              <Grid item md={6} container direction="column">
                <Typography key="3" color="text.primary">
                  <b>Gender </b>
                </Typography>
                <Typography key="3" style={{ color: "#919eab" }}>
                  {patient.gender}
                </Typography>
              </Grid>
              <Grid item md={12} container direction="column">
                <Typography key="3" color="text.primary">
                  <b>Birth Date </b>
                </Typography>
                <Typography key="3" style={{ color: "#919eab" }}>
                  {dateYearFormatWithAge(patient.birthDate)}
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Typography
                  key="3"
                  color="text.primary"
                  style={{ marginBottom: 6 }}
                >
                  <b>The HJ Assessment is for the member? </b>
                </Typography>
                <TextField
                  fullWidth
                  id="outlined-select-currency-native"
                  select
                  {...getFieldProps("isForPatient")}
                  error={Boolean(touched.isForPatient && errors.isForPatient)}
                  helperText={touched.isForPatient && errors.isForPatient}
                  SelectProps={{
                    native: true,
                  }}
                >
                  <option value="" disabled></option>
                  <option value={"Yes"}>Yes</option>
                  <option value={"No"}>No</option>
                </TextField>
              </Grid>
              {((guardianNotContact && values?.isForPatient === "Yes")) &&
                values.isForPatient === "Yes" && (
                  <Grid item md={12} style={{ paddingTop: "5px" }}>
                    <Typography variant="caption" color="red">
                      Patient does not have email or phone number, please, enter at least one 
                    </Typography>
                  </Grid>
                )}
              <Grid item md={12}>
                <Typography key="3" color="text.primary">
                  <b>Choose Available Guardian </b>
                </Typography>
              </Grid>
              <Grid item md={12}>
                <TextField
                  fullWidth
                  id="outlined-select-currency-native"
                  select
                  {...getFieldProps("guardian")}
                  error={Boolean(touched.guardian && errors.guardian)}
                  helperText={touched.guardian && errors.guardian}
                  onChange={(event) =>
                    setFieldValue("guardian", event.target.value)
                  }
                  SelectProps={{
                    native: true,
                  }}
                >
                  {patientContacts &&
                  patientContacts.length > 0 &&
                  values.isForPatient === "No" ? (
                    <>
                      <option value="" label="Select Parent/Legal Guardian" />
                      {selectContactValues()}
                    </>
                  ) : (
                    <option value="No Applicable" disabled>
                      {"No Applicable"}
                    </option>
                  )}
                </TextField>

                {(guardianNotContact && 
                  values?.isForPatient === "No" && 
                  values?.guardian !== "" && values?.guardian !== "No Applicable"
                ) && 
                  <Grid item md={12} style={{ paddingTop: "5px" }}>
                    <Typography variant="caption" color="red">
                      Guardian does not have email or phone number, please, enter at least one 
                    </Typography>
                  </Grid>
                }
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOrderModalOpen(false)}
              color="error"
              type="reset"
            >
              Cancel
            </Button>
            <Button
              color="primary"
              type="submit"
              disabled={disableCreateRequest || guardianNotContact}
            >
              Create HJ Assessment
            </Button>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  ) : null;
}
