import {useState} from 'react'
import {
    Grid, 
    Typography, 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField
} from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import Cookies from 'js-cookie';
import useAuth from 'hooks/useAuth';
import { urlBackNestApp } from 'routes/urls';

const useStyles = makeStyles(() => ({
    info: {
        marginLeft: 6,
        marginRight: 6,
        fontSize: 14,
    },
}))

export default function Feedback({env}: {env: Array<any>}){
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const {user} = useAuth()

    const generalInfoSchema = Yup.object().shape({
        userComment: Yup.string().required('Comment is required'),
    })

    const formik = useFormik({
        initialValues: {
            userComment: "",
            type: ''
        },
        validationSchema: generalInfoSchema,
        onSubmit: async(values) => {
            const data = {
                ...values,
                ...handleFeedback()
            }
            try {
                await fetch(`${urlBackNestApp}/app-info/feedback/${user!.userName}`,{
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data)
                })
                resetForm()
                setOpen(false);
            } catch(e) {
                console.log(`Something fail try to add user ${e}`)
            }
        }
    })

    const handleClose = () => {
        setOpen(false);
        resetForm()
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleFeedback = () => {
        const currentUrl = window.location.href
        const currentStorage = localStorage.length > 0 ? `Exist Local Storage ${localStorage.length}` : 'Not Exist'
        const currentCookie = Cookies.get() ? `Exist Cookies` : 'Not Exist'
 
        const currentEnv = [
            {
                name: 'Client',
                branch: env[0].branch,
                date: env[0].date,
                shortHash: env[0].shortHash 
            },
            {
                name: 'Server',
                branch: env[1].branch,
                date: env[1].date,
                shortHash: env[1].shortHash
            }
        ]
        return {
            env: JSON.stringify(currentEnv),
            currentUrl,
            cookies: currentCookie,
            localStorage: currentStorage,
            userName: user!.userName
        }
    }

    const { errors, touched, isSubmitting, getFieldProps, resetForm } = formik;
    
    return (
        <>   
            <Button fullWidth onClick={handleClickOpen} variant="contained" color="primary">
                Any Feedback?
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate>
                        <DialogTitle id="alert-dialog-title">{"Feedback"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Let us know your feedback and we comunicate later.
                            </DialogContentText>
                            <Grid container spacing={3}>
                                <Grid item md={12}>
                                    <TextField
                                        fullWidth
                                        id="outlined-select-currency-native"
                                        select
                                        autoComplete="Type"
                                        label="Type"
                                        {...getFieldProps("type")}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        error={Boolean(touched.type && errors.type)}
                                        helperText={touched.type && errors.type}
                                    >
                                        <option value="" disabled></option>
                                        <option value={"Bug"}>Bug</option>
                                        <option value={"Improvement"}>Improvement</option>
                                        <option value={"Other"}>Other</option>
                                    </TextField>
                                </Grid>
                                <Grid item md={12}>
                                    <TextField
                                        fullWidth
                                        label="Comment"
                                        {...getFieldProps('userComment')}
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                        error={Boolean(touched.userComment && errors.userComment)}
                                        helperText={touched.userComment && errors.userComment}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Disagree
                        </Button>
                        <Button type="submit" disabled={isSubmitting} color="primary" autoFocus>
                            Agree
                        </Button>
                        </DialogActions>
                    </Form>
                </FormikProvider>
            </Dialog>
        </>
    )
}