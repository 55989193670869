import { TextField } from "@material-ui/core";
import InputMask from 'react-input-mask';
 
export const InputMasked = (props: any) => (
  <InputMask mask="999-999-9999" {...props}>
    {(inputProps: any) => <TextField 
      {...inputProps} 
      type="tel" 
      error={props.error} 
      helperText={props.helperText}
    />}
  </InputMask>
);