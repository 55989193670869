import { Box, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  closeBtn: {
    color: "#fff",
    backgroundColor: "#FF4842",
    "&:hover": {
      backgroundColor: "#FF4842",
    },
  },
  nextBtn: {
    color: "#fff",
    backgroundColor: "#00AB55",
    "&:hover": {
      backgroundColor: "#00AB55",
    },
  },
});

const Transitions = ({ transitions, handleTransition }: any) => {
  const classes = useStyles({});

  const displayButtons = transitions
    ?.sort((a: any, b: any) => a.code.localeCompare(b.code))
    .map((item: any, index: 0) => {
      const parseMeta = JSON.parse(item?.metadata);

      return (
        <>
          <Button
            className={classes?.[parseMeta?.ui?.className] || null}
            onClick={() => handleTransition(item)}
          >
            {item.name}
          </Button>

          {index === 0 && <Box sx={{ flex: "1 1 auto" }} />}
        </>
      );
    });

  return displayButtons;
};

export default Transitions;
