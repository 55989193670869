import { urlBackNestApp } from "routes/urls";
import { Button, Grid, useTheme } from "@material-ui/core";
import KeyValueGrid from "components/KeyValueGrid";
import KeyValuePaper from "components/KeyValuePaper";
import { ITaskRowInfo } from "../../@types/patientEngagement";
import { capitalCase } from "change-case";
import { Alert } from "@mui/material";

const TaskRow = ({ taskRowInfo }: ITaskRowInfo) => {
  const answerNat = (taskNumber: string) => {
    const url = `${urlBackNestApp}/redirect/request?requestNo=${taskNumber}&request-no=${taskNumber}&id=${taskRowInfo.userName}`;
    window.open(url);
  };

  const theme = useTheme();;
  
  const setStatus = (rowStatus: string) => {
    switch (rowStatus) {
      case 'in-progress':
        return {
          severity: theme.palette.success.main,
          message: capitalCase(rowStatus)
        };
      case 'completed':
        return {
          severity: theme.palette.info.main,
          message: capitalCase(rowStatus)
        };
      case 'requested':
        return {
          severity: theme.palette.secondary.main,
          message: capitalCase(rowStatus)
        };
      case 'rejected':
        return {
          severity: theme.palette.error.main,
          message: capitalCase(rowStatus)
        };
      case 'on-hold':
        return {
          severity: theme.palette.warning.main,
          message: capitalCase(rowStatus)
        };
      default:
        return {
          severity: theme.palette.grey[0],
          message: capitalCase(rowStatus)
        };
    }
  };

  return (
    <Grid container item md={12} key={taskRowInfo.id}>
      <KeyValuePaper>
        <KeyValueGrid label="HJ Invitation No." value={taskRowInfo.taskNumber} />
        <KeyValueGrid label="Name" value={taskRowInfo.name} />
        <KeyValueGrid label="Type" value={taskRowInfo.type} />
        <KeyValueGrid label="Status" 
          value={taskRowInfo.status} 
          children={
            <Alert icon={false}
              sx={{ 
                backgroundColor: `${setStatus(taskRowInfo.status)?.severity}`, 
                height: '30px',
                fontWeight: 'bold',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                padding: 1
              }}
            >
              {setStatus(taskRowInfo.status)?.message}
            </Alert>
          }
        />
        <Grid
          container
          item
          md={4}
          key={taskRowInfo.id}
          alignContent="center"
          justifyContent={"center"}
        >
          <Button
            variant="contained"
            onClick={() => {
              answerNat(taskRowInfo.taskNumber);
            }}
          >
            Open
          </Button>
        </Grid>
      </KeyValuePaper>
    </Grid>
  );
};

export default TaskRow;
