import { useRef } from 'react';
import { Button, Divider, Drawer, Grid, List, ListItem, MenuItem, Select, Stack, TextField, Typography, Snackbar, Alert } from '@material-ui/core';
import axios from 'axios';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { urlBackNestApp } from 'routes/urls';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import DatePicker from '@material-ui/lab/DatePicker';
import DateTimePicker from '@material-ui/lab/DateTimePicker';
import agent from "../../api/agent";
import * as Yup from 'yup';
import useAuth from "hooks/useAuth";
import MuiAlert from '@material-ui/lab/Alert';
import usePrintableTaskInfo from '../../hooks/usePrintableTaskInfo';
import {
  checkAclValidation,
  SysAdminAcl,
  PatientElegibilityEditAcl,
  PatientScoreEditAcl
} from "guards/RoleBasedGuard";

interface ConfirmContactProps {
  fhirPatient: any,
  patientEngagement: any,
  isOpen: boolean,
  handlerIsOpen: Function,
  checklist: any,
  workflow?: any,
  checklistHandler?: any,
  refreshContactAttempt?: any,
  openDispositon: any;
  refreshData: any;
}

export default function ConfirmContact({ fhirPatient, patientEngagement, isOpen, handlerIsOpen, checklist, checklistHandler, refreshContactAttempt, refreshData, openDispositon }: ConfirmContactProps) {
  const [confirmContactModalOpen, setConfirmContactModalOpen] = useState<boolean>(isOpen);
  const [dateTime, setDateTime] = useState<Date | null>(
    new Date('2014-08-18T21:11:54'),
  );
  const [contactOn, setContactOn] = useState<any>(new Date());
  const [nextContactOn, setNextContactOn] = useState<any>(new Date());
  const [outcome, setOutcome] = useState<any>();
  const [duration, setDuration] = useState<any>();
  const [saveBtnDisabled, setSaveBtnDisabled] = useState<any>(false);
  const [openSnackbar, setOpenSnackbar] = useState<any>(false);
  const [successMessageSnackbar, setSuccessMessageSnackbar] = useState<any>();
  const [disableInckEligible, setDisableInckEligible] = useState<any>(false);
  const [snackBarMessageText, setSnackBarMessageText] = useState<any>();
  const [attachmentPatientOpen, setAttachmentPatientOpen] = useState(false);

  const ref: any = useRef(null);
  const { user } = useAuth();

  const outcomeItems = [
    {
      id: 1,
      label: 'Intake Visit Scheduled',
      value: 'Intake Visit Scheduled',
    },
    {
      id: 2,
      label: 'Interested - requires follow-up',
      value: 'Interested - requires follow-up',
    },
    {
      id: 3,
      label: 'No answer - voicemail left',
      value: 'No answer - voicemail left',
    },
    {
      id: 4,
      label: 'No answer - no voicemail left',
      value: 'No answer - no voicemail left',
    },
    {
      id: 5,
      label: 'Opt out - specify reason',
      value: 'Opt out - specify reason',
    },
    {
      id: 6,
      label: 'Prompt to Call Letter',
      value: 'Prompt to Call Letter',
    },
    {
      id: 7,
      label: 'Wrong number',
      value: 'Wrong number',
    },
    {
      id: 8,
      label: 'Other - specify in notes',
      value: 'Other - specify in notes',
    },
  ];

  const initialValues = {
    dateHour: dateTime,
    type: '',
    contactOn: new Date(),
    nextContactOn: new Date(),
    outcome: '',
    duration: '',
    note: '',
  };

  const confirmContactSchema = {
    dateHour: Yup.date().required('The date and hour is required'),
    type: Yup.string(),
    contactOn: Yup.date().default(() => new Date()),
    nextContactOn: Yup.date().default(() => new Date()),
    outcome: Yup.string().required('The outcome it is required'),
    duration: Yup.number().required('Duration it is required'),
    note: Yup.string().required('Note it is required'),
  };

  useEffect(() => {
    setConfirmContactModalOpen(isOpen);
  }, [isOpen]);

  const CANCELLED = 'cancelled';
  const INCK_ELIGIBLE = 'InCK-Eligible';

  const isInckEligible = (data: any[] = []) => {
    return !!data.find((i: any) => i?.code === INCK_ELIGIBLE);
  };

  const handleInckEligible = async (memberId: any, isEnrolled: boolean = false) => {
    setDisableInckEligible(true);

    const printableInfo = await agent.FhirPatient.printableInfo(memberId);

    try {
      const result = await agent.FhirPatient.updatePatientToEnroll(memberId, printableInfo);
      if (result && typeof result === 'object' && Object.keys(result).length > 0) {
        if (refreshData) {
          refreshData();
        }
        setTimeout(() => setDisableInckEligible(false), 4000);
      }
    } catch (err) {
      console.log('error on handleInckEligible', err);
    }
  };

  const handleSaveContactAttempt = async () => {
    setSaveBtnDisabled(true);
    const { values } = ref.current;
    const notePayload = {
      contactOn: values?.contactOn || null,
      nextContactOn: values?.nextContactOn || null,
      outcome: values?.outcome || null,
      duration: values?.duration || null,
      note: values?.note || null,
      patientRef: patientEngagement.id,
      checklist,
      patientEngagement,
    };

    delete values?.dateHour;
    delete values?.type;

    const validation = Object.values(values).some((i: any) => i === '');

    if (validation) {
      setOpenSnackbar(true);
      setSaveBtnDisabled(false);
    } else {
      try {
        if (values?.outcome === "Opt out - specify reason" || values?.outcome === "Prompt to Call Letter") {
          handlerIsOpen(false);
          setConfirmContactModalOpen(false);
          openDispositon(true);
          await agent.PatientEngagement.markContactAttemptAsCompleted(notePayload);
          return;
        }

        await agent.PatientEngagement.savePatientContactAttempt(notePayload);
        handlerIsOpen(false);
        setConfirmContactModalOpen(false);

        // Resetting initial values
        ref.current.resetForm();
        ref.current.setFieldValue('contactOn', new Date());
        ref.current.setFieldValue('nextContactOn', new Date());
        ref.current.setFieldValue('outcome', '');
        ref.current.setFieldValue('duration', '');
        ref.current.setFieldValue('note', '');

        if (checklistHandler) checklistHandler();
        if (refreshContactAttempt) refreshContactAttempt();
      } catch (err) {
        console.log('error on saving contact attempt...', err);
      } finally {
        setSaveBtnDisabled(false);
        setContactOn(new Date());
        setNextContactOn(new Date());
        setOutcome('');
        setDuration('');
      }
    }
  };

  const handleAttachmentClose = () => setAttachmentPatientOpen(false);

  const handleCloseSnackbar = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const handleSubmit = async (values: any) => {
    const data = {
      ...values,
      checklistItemId: checklist.id,
      workflowStageChecklistRef: null,
      patientEngagementRef: patientEngagement.id,
      code:'CONTACT_ITEM',
      message:'Contacts confirmed'
    };

    axios.post(`${urlBackNestApp}/patientengagement/checkitem`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('encryptUser')}`
      }
    })
      .then((response) => {
        handlerIsOpen(false);
        setConfirmContactModalOpen(false);
        if (checklistHandler) checklistHandler();
      })
      .catch((reason) => {
        console.log(reason);
      });
  };

  const handleChangeType = () => { };
  const handleChangeDateTime = (newDateTime: Date | null) => {
    setDateTime(newDateTime);
  };

  return (
    <Drawer
      // PaperProps={{
      //   sx: { width: "30vw" },
      // }}
      anchor="left"
      open={isOpen}
      onClose={() => {
        handlerIsOpen(false);
      }}
    >
      <Formik
        innerRef={ref}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {(props: any) => {
          return (
            <Form id="confirmContactForm" autoComplete="off">
              <List component="nav">
                <ListItem>
                  <Grid width={800} container justifyContent="space-between">
                    <Stack direction="row" spacing={2}>
                      {/* <Button variant="contained" type="submit" disabled={props.isSubmitting}>Confirm Contacts</Button> */}
                      <Button
                        onClick={() => {
                          setConfirmContactModalOpen(false);
                          handlerIsOpen(false);
                        }}
                      >
                        Cancel
                      </Button>

                      {fhirPatient?.id && checkAclValidation({ user, acl: SysAdminAcl }) && (
                        <Grid
                          item
                          container
                          justifyContent="flex-end"
                          sx={{ marginTop: "-24px" }}
                        >
                          <Button
                            variant="contained"
                            color={
                              isInckEligible(fhirPatient?.meta?.tag)
                                ? "error"
                                : "primary"
                            }
                            onClick={() =>
                              handleInckEligible(
                                fhirPatient?.id,
                                isInckEligible(fhirPatient?.meta?.tag)
                              )
                            }
                            disabled={disableInckEligible}
                          >
                            {isInckEligible(fhirPatient?.meta?.tag)
                              ? "Un-Enroll"
                              : "Enroll"}
                          </Button>
                        </Grid>
                      )}
                    </Stack>
                    <Grid container spacing={3} sx={{ marginTop: 5 }}>
                      <Grid item md={12}>
                        <Typography
                          variant="subtitle1"
                          component="div"
                          sx={{ marginBottom: 2 }}
                        >
                          Contact On
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DateTimePicker
                            value={contactOn}
                            onChange={(newContactOn: any) => {
                              props?.setFieldValue("contactOn", newContactOn);
                              setContactOn(newContactOn);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                          {/* <DatePicker
                              value={contactOn}
                              onChange={(newContactOn: any) => {
                                props?.setFieldValue('contactOn', newContactOn);
                                setContactOn(newContactOn);
                              }}
                              renderInput={(params) => <TextField {...params} />}
                            /> */}
                        </LocalizationProvider>
                      </Grid>
                      <Grid item md={12}>
                        <Typography
                          variant="subtitle1"
                          component="div"
                          sx={{ marginBottom: 2 }}
                        >
                          Next Contact On
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            value={nextContactOn}
                            onChange={(newNextContactOn: any) => {
                              props?.setFieldValue(
                                "nextContactOn",
                                newNextContactOn
                              );
                              setNextContactOn(newNextContactOn);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item md={12}>
                        <Typography
                          variant="subtitle1"
                          component="div"
                          sx={{ marginBottom: 2 }}
                        >
                          Outcome
                        </Typography>
                        <TextField
                          id="outcome-container"
                          select
                          label="Select"
                          value={outcome}
                          onChange={(outcomeSelected: any) => {
                            props?.setFieldValue(
                              "outcome",
                              outcomeSelected.target.value
                            );
                            setOutcome(outcomeSelected.target.value);
                          }}
                          helperText="Please select outcome"
                        >
                          {outcomeItems.map((option) => (
                            <MenuItem key={option.id} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item md={12}>
                        <Typography
                          variant="subtitle1"
                          component="div"
                          sx={{ marginBottom: 2 }}
                        >
                          Duration
                        </Typography>
                        <TextField
                          id="duration-container"
                          type="number"
                          label="Duration"
                          value={duration}
                          onChange={(durationTyped: any) => {
                            props?.setFieldValue(
                              "duration",
                              durationTyped.target.value
                            );
                            setDuration(durationTyped.target.value);
                          }}
                        >
                          {outcomeItems.map((option) => (
                            <MenuItem key={option.id} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item md={12}>
                        <Typography
                          variant="subtitle1"
                          component="div"
                          sx={{ marginBottom: 2 }}
                        >
                          Add Note
                        </Typography>
                        <TextField
                          id="note"
                          label="Enter your note..."
                          multiline
                          rows={4}
                          name="note"
                          fullWidth
                          onChange={(event) =>
                            props?.setFieldValue("note", event.target.value)
                          }
                          sx={{ marginBottom: 3 }}
                        />
                      </Grid>
                      <Grid item md={12}>
                        <Grid container justifyContent="flex-end">
                          <Button
                            variant="contained"
                            color="success"
                            disabled={saveBtnDisabled}
                            onClick={handleSaveContactAttempt}
                          >
                            Save
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Snackbar
                      autoHideDuration={3000}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      open={attachmentPatientOpen}
                      onClose={handleAttachmentClose}
                      key={"topcenter"}
                      children={
                        <MuiAlert
                          elevation={6}
                          variant="filled"
                          severity="success"
                          onClose={handleAttachmentClose}
                        >
                          {!snackBarMessageText
                            ? "Attachment Added to Patient"
                            : snackBarMessageText}
                        </MuiAlert>
                      }
                    />

                    <Stack spacing={2} sx={{ width: "100%" }}>
                      <Snackbar
                        open={openSnackbar}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackbar}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                      >
                        <Alert
                          onClose={handleCloseSnackbar}
                          severity="error"
                          sx={{ width: "100%" }}
                        >
                          All fields are required, please confirm
                        </Alert>
                      </Snackbar>
                    </Stack>
                  </Grid>
                </ListItem>
              </List>
            </Form>
          );
        }}
      </Formik>
    </Drawer>
  );
}