import React, { useRef, useState } from 'react';
import { Box, Link } from '@material-ui/core';
import { MIconButton } from '../../components/@material-extend';
import MenuPopover from '../../components/MenuPopover';
import { SupportAgent } from '@material-ui/icons';
import useAuth from 'hooks/useAuth';


const UserSupport = () => {
  const [isOpenNotification, setIsOpenNotification] = useState(false);
  const anchorRef = useRef(null);

  const handleOpenNotifications = () => setIsOpenNotification(true);
  const handleCloseNotifications = () => setIsOpenNotification(false);

  return (
    <>
      <MIconButton ref={anchorRef} onClick={handleOpenNotifications}>
        <SupportAgent />
      </MIconButton>

      <MenuPopover
        open={isOpenNotification}
        onClose={handleCloseNotifications}
        anchorEl={anchorRef.current}
        sx={{ width: 400 }}
      >
        <Box sx={{ alignItems: "center", py: 2, px: 2.5, display: "flex" }}>
          <Box sx={{ flexGrow: 1 }}>
            <span style={{ marginRight: "5px", fontSize: 14 }}>
            Do you need support, go to the{" "}
              <Link
                href="https://share.hsforms.com/1tcHsSLHaRz2jjJXniiSlTQ3qqda"
                color="primary"
                target="_blank"
                rel="noreferrer"
              >
                following form
              </Link>
            </span>
          </Box>
        </Box>
      </MenuPopover>
    </>
  );
};

export default UserSupport;