import { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  Stack,
  Typography,
  Drawer,
  TextField,
  InputLabel,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { Form,  Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { urlBackNestApp } from "routes/urls";

export default function ConfirmCMOptInItem({
  isOpen,
  handlerIsOpen,
  addPatientEngagementChecklistItem,
}: any) {
  const [confirmModalOpen, setConfirmModalOpen] = useState(isOpen);
  const [optInType, setOptInType] = useState([]);
  const [optInDecisionCatalog, setOptInDecisionCatalog] = useState([]);

  useEffect(() => {
    setConfirmModalOpen(isOpen);
  }, [isOpen]);

  const  initialValues = {
    opt_in_decision: "",
    type: "",
    notes: "",
    dispose: false,
  };

  const schema = Yup.object().shape({
    opt_in_decision: Yup.string().required("Opt-Out Decision is required"),
    type: Yup.string().required("Type is required"),
    notes: Yup.string().required("Notes are required"),
    dispose: Yup.boolean(),
  });

  const getOptInType = async () => {
    const optInTypeCode = "OPT_IN_TYPES";
    axios
      .get(`${urlBackNestApp}/catalog/${optInTypeCode}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("encryptUser")}`,
        },
      })
      .then((response) => {
        setOptInType(response.data);
      })
      .catch((reason) => {
        console.log(reason);
      });
  };

  const getOptInDecisions = async () => {
    const optInDecisionCode = "OPT_IN_DECISIONS";
    axios
      .get(`${urlBackNestApp}/catalog/${optInDecisionCode}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("encryptUser")}`,
        },
      })
      .then((response) => {
        setOptInDecisionCatalog(response.data);
      })
      .catch((reason) => {
        console.log(reason);
      });
  };

  useEffect(() => {
    getOptInType();
    getOptInDecisions();
  }, []);

  const handleSubmit = async (values: any) => {
    console.log("values: ", values);

    const valuesToSubmit = { ...values };
    delete valuesToSubmit.dispose;

    try {
      console.log(
        "PatientEngamement ConfirmCMOptInItem valuesToSubmit: ",
        valuesToSubmit
      );
      addPatientEngagementChecklistItem(
        "CONFIRM_CM_OPTIN_ITEM",
        "CM Opt-Out Confirmed",
        valuesToSubmit,
        false,
        values.dispose
      );
      handlerIsOpen(false);
      setConfirmModalOpen(false);
    } catch (error) {
      console.log("failed confirming CM Opt-Out");
    }
  };

  return (
    <Drawer
      PaperProps={{
        sx: { width: "60%" },
      }}
      anchor="left"
      open={confirmModalOpen}
      onClose={() => {
        setConfirmModalOpen(false);
        handlerIsOpen(false);
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {(props: any) => {
          return (
            <Form id="confirmCMOptIn" autoComplete="off">
              <List component="nav">
                <ListItem>
                  <Grid container>
                    <Stack direction="row" spacing={2}>
                      <Button
                        variant="contained"
                        type="submit"
                        disabled={props.isSubmitting}
                      >
                        Confirm CM Opt-Out
                      </Button>
                      <Button
                        onClick={() => {
                          setConfirmModalOpen(false);
                          handlerIsOpen(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Stack>
                  </Grid>
                </ListItem>
              </List>
              <Divider sx={{ marginBottom: 2 }} />
            
              <Grid
                container
                direction="column"
                alignItems="center"
                spacing={0}
                xs={10}
                ml={2}
                mr={2}
              >
                <Grid container item direction="row" alignItems="center">
                  <Grid container item xs={3} justifyContent="start">
                    <InputLabel htmlFor="opt_in_decision">
                    Opt-Out Decision
                    </InputLabel>
                  </Grid>
                  <Grid container item xs={9} justifyContent="start">
                    <TextField
                      fullWidth
                      id="opt_in_decision"
                      select
                      variant="standard"
                      label="Opt-Out Desicion"
                      {...props.getFieldProps("opt_in_decision")}
                      error={Boolean(
                        props.touched.opt_in_decision && props.errors.opt_in_decision
                      )}
                      helperText={
                        props.touched.opt_in_decision && props.errors.opt_in_decision
                          ? props.errors.opt_in_decision
                          : " "
                      }
                    >
                      <MenuItem key={""} value={""} disabled></MenuItem>
                      {optInDecisionCatalog.map((opt_in_decision: any) => (
                        <MenuItem
                          key={opt_in_decision.shortDisplayName}
                          value={opt_in_decision.shortDisplayName}
                        >
                          {opt_in_decision.shortDisplayName}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>

                <Grid container item direction="row" alignItems="center">
                  <Grid container item xs={3} justifyContent="start">
                    <InputLabel htmlFor="confirm_cm_type">Type</InputLabel>
                  </Grid>
                  <Grid container item xs={9} justifyContent="start">
                    <TextField
                      fullWidth
                      id="confirm_cm_type"
                      select
                      variant="standard"
                      label="Type"
                      {...props.getFieldProps("type")}
                      error={Boolean(props.touched.type && props.errors.type)}
                      helperText={props.touched.type && props.errors.type ? props.errors.type : " "}
                    >
                      <MenuItem key={""} value={""} disabled></MenuItem>
                      {optInType.map((type: any) => (
                        <MenuItem
                          key={type.shortDisplayName}
                          value={type.shortDisplayName}
                        >
                          {type.shortDisplayName}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>

                <Grid container item direction="row" alignItems="center">
                  <Grid container item xs={3} justifyContent="start">
                    <InputLabel htmlFor="confirm_cm_notes">Notes</InputLabel>
                  </Grid>
                  <Grid container item xs={9} justifyContent="start">
                    <TextField
                      fullWidth
                      id="confirm_cm_notes"
                      variant="standard"
                      label="Type Notes"
                      multiline
                      minRows={3}
                      {...props.getFieldProps("notes")}
                      error={Boolean(props.touched.notes && props.errors.notes)}
                      helperText={
                        props.touched.notes && props.errors.notes ? props.errors.notes : " "
                      }
                    />
                  </Grid>
                </Grid>

                {props.values.opt_in_decision === "Deny" && (
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    minHeight={60}
                  >
                    <Grid container item xs={4}>
                      <Typography>Close engagement box</Typography>
                    </Grid>

                    <Grid container item xs={8}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...props.getFieldProps("dispose")}
                            checked={props.values.dispose}
                          />
                        }
                        label=""
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Drawer>
  );
}
