import {
  Accordion,
  AccordionSummary,
  Grid,
  GridSize,
  Paper,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import KeyValueGrid from "components/KeyValueGrid";
import { FhirDataTypesMod } from "models/fhirDataTypes";
import { indexedInt } from "models/fhirQuery";
import { FhirMeasureReportMod } from "models/measureReport";
import { FhirQuestionnaireMod } from "models/questionnaire";
import { FhirQuestionnaireResponseMod } from "models/questionnaireResponse";
import { getMeasureReportGroups, getSplitReference } from "utils/fhirUtils";
import QuestRes from "./QuestRes";

export type Risks =
  | "none"
  | "low-risk"
  | "medium-risk"
  | "high-risk"
  | "indeterminate-risk";

interface RiskColor {
  backgroundClass: string;
  normalClass: string;
}

const getRiskColor = (value: Risks) => {
  const riskColorBackgroundClassPrefix = "measure-report-background-indicator-";
  const riskColorNormalClassPrefix = "measure-report-indicator-";
  let riskColor: RiskColor = {
    backgroundClass: riskColorBackgroundClassPrefix + "indeterminate-risk",
    normalClass: riskColorNormalClassPrefix + "indeterminate-risk",
  };

  switch (value) {
    case "indeterminate-risk":
      riskColor.backgroundClass =
        riskColorBackgroundClassPrefix + "indeterminate-risk";
      riskColor.normalClass = riskColorNormalClassPrefix + "indeterminate-risk";
      break;
    case "none":
      riskColor.backgroundClass = riskColorBackgroundClassPrefix + "none";
      riskColor.normalClass = riskColorNormalClassPrefix + "none";
      break;
    case "low-risk":
      riskColor.backgroundClass = riskColorBackgroundClassPrefix + "low-risk";
      riskColor.normalClass = riskColorNormalClassPrefix + "low-risk";
      break;
    case "medium-risk":
      riskColor.backgroundClass =
        riskColorBackgroundClassPrefix + "medium-risk";
      riskColor.normalClass = riskColorNormalClassPrefix + "medium-risk";
      break;
    case "high-risk":
      riskColor.backgroundClass = riskColorBackgroundClassPrefix + "high-risk";
      riskColor.normalClass = riskColorNormalClassPrefix + "high-risk";
      break;
  }

  return riskColor;
};

export const getMeasureReportGroupValue = (group: FhirMeasureReportMod.Group) => {
  const measureValue =
    group?.stratifier?.[0].stratum?.[0].measureScore?.value || "";

  return measureValue;
};

export const getObservationCodeableConceptValue = (group: any) => {
  const getObservationValue =
    group?.resource?.valueCodeableConcept?.coding?.[0] || {};
  const observationValue =
    (getObservationValue &&
      !!Object.keys(getObservationValue).length &&
      `${getObservationValue?.code} (${getObservationValue?.display})`) ||
    "";

  return observationValue;
};

export const getIntObservationCodeableConceptValue = (group: any) => {
  const getObservationValue =
    group?.resource?.valueCodeableConcept?.coding?.[0] || {};
  return (
    (getObservationValue &&
      !!Object.keys(getObservationValue).length &&
      getObservationValue?.code) ||
    ""
  );
};

export const getObservationIntegerValue = (group: any) => {
  const observationValue = group?.resource?.valueInteger || "";

  return observationValue;
};

export const getRisk = (riskText: string): Risks => {
  let casted: Risks = "none";

  switch (riskText) {
    case "none":
      casted = "none";
      break;
    case "low-risk":
      casted = "low-risk";
      break;
    case "medium-risk":
      casted = "medium-risk";
      break;
    case "high-risk":
      casted = "high-risk";
      break;
    case "high risk":
      casted = "high-risk";
      break;
    case "indeterminate-risk":
      casted = "indeterminate-risk";
      break;
    default:
      casted = "none";
  }

  return casted;
};

interface RiskComponentProps {
  mainText: string;
  riskText: string;
  riskCode: string;
  score: string;
  onClickRiskCompMainText: () => void;
}

function RiskComponent({
  mainText,
  riskText,
  riskCode,
  score,
  onClickRiskCompMainText,
}: RiskComponentProps) {
  const riskStr: Risks = getRisk(riskCode);
  const riskColor = getRiskColor(riskStr);

  return (
    <Grid container item>
      <Grid
        container
        item
        md={4}
        alignItems="center"
        onClick={(e) => {
          e.stopPropagation();
          onClickRiskCompMainText();
        }}
      >
        <Typography>{mainText}</Typography>
      </Grid>
      <Grid
        container
        item
        className={riskColor.backgroundClass}
        md={8}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item className={riskColor.normalClass} md={4}>
          <Typography style={{ paddingLeft: 8 }}>{`${riskCode}`}</Typography>
        </Grid>
        {riskText !== riskCode && (
          <Grid
            container
            item
            justifyContent="center"
            className={riskColor.normalClass}
            md={6}
          >
            <Typography>{riskText}</Typography>
          </Grid>
        )}
        <Grid
          container
          item
          justifyContent="center"
          className={riskColor.normalClass}
          md={2}
        >
          <Typography>{score}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minHeight: "40px",
  },
  rootExpanded: {
    background: "blue",
    flexGrow: 1,
  },
  highlight: {
    backgroundColor: "#000",
    color: "#FFF",
    paddingBottom: "8px",
    marginTop: "8px",
  },
}));

interface RequestReportProps {
  indexedFhirQuestionnaires: indexedInt<FhirQuestionnaireMod.Questionnaire>;
  indexedfhirQuestionnaireResponses: indexedInt<FhirQuestionnaireResponseMod.QuestionnaireResponse>;
  measureReport: FhirMeasureReportMod.MeasureReport;
  onClickMeasureGroup: (questionnaireResponseUuid: string) => void;
  devMode: boolean;
  showMeasureQuestionnaireResponses: boolean;
}

export interface MeasureReportGroups {
  medicalComplex?: FhirMeasureReportMod.Group;
  socialComplex?: FhirMeasureReportMod.Group;
  serviceIntegration?: FhirMeasureReportMod.Group;
  normal?: FhirMeasureReportMod.Group[];
}

export default function RequestReport({
  indexedFhirQuestionnaires,
  indexedfhirQuestionnaireResponses,
  measureReport,
  onClickMeasureGroup,
  devMode,
  showMeasureQuestionnaireResponses,
}: RequestReportProps) {
  const classes = useStyles();

  const getComponentRender = (
    index: number,
    component: FhirMeasureReportMod.Component
  ) => {
    return (
      <>
        <KeyValueGrid
          label={"stratum.component.[" + index + "].value.text"}
          value={component.value.text === undefined ? "" : component.value.text}
        />

        <KeyValueGrid
          label={"stratum.component.[" + index + "].code.text"}
          value={component.code.text === undefined ? "" : component.code.text}
        />

        {component.value.coding?.map(
          (item2: FhirDataTypesMod.Coding, index2: number) => {
            return getCodeableConceptRender(
              "stratum.component.[" + index + "].value.coding.[" + index2 + "]",
              item2
            );
          }
        )}

        {component.code.coding?.map(
          (item2: FhirDataTypesMod.Coding, index2: number) => {
            return getCodeableConceptRender(
              "stratum.component.[" + index + "].code.coding.[" + index2 + "]",
              item2
            );
          }
        )}
      </>
    );
  };

  const getCodeableConceptRender = (
    preText: string,
    coding: FhirDataTypesMod.Coding
  ) => {
    return (
      <>
        <KeyValueGrid
          label={preText + ".code"}
          value={coding.code ? coding.code : ""}
        />
        <KeyValueGrid
          label={preText + ".display"}
          value={coding.display ? coding.display : ""}
        />
        <KeyValueGrid
          label={preText + ".system"}
          value={coding.system ? coding.system : ""}
        />
      </>
    );
  };

  const getStratumRender = (
    stratum: FhirMeasureReportMod.Stratum,
    onClickRiskCompMainText: () => void
  ) => {

    return (
      <Grid container item md={12}>
        <RiskComponent
          mainText={
            stratum.component?.[1]?.value?.text
              ? stratum.component?.[1]?.value?.text
              : ""
          }
          riskText={
            stratum.component?.[0]?.value?.coding?.[0]?.display
              ? stratum.component?.[0]?.value?.coding?.[0]?.display
              : ""
          }
          riskCode={
            stratum.component?.[0]?.code?.coding?.[0]?.code
              ? stratum.component?.[0]?.code?.coding?.[0]?.code
              : ""
          }
          score={
            stratum.measureScore?.value === undefined
              ? ""
              : String(stratum.measureScore?.value)
          }
          onClickRiskCompMainText={onClickRiskCompMainText}
        />
        {devMode && (
          <Grid container item md={12} direction="row">
            <Grid
              className={classes.highlight}
              container
              item
              md={12}
              direction="column"
            >
              {stratum.value?.text && (
                <KeyValueGrid
                  label={"stratum.value.text"}
                  value={stratum.value.text ? stratum.value.text : ""}
                />
              )}

              {stratum.measureScore && (
                <KeyValueGrid
                  label={"stratum.measureScore.value"}
                  value={
                    stratum.measureScore.value === undefined
                      ? ""
                      : String(stratum.measureScore.value)
                  }
                />
              )}

              {stratum.value?.coding?.map(
                (value: FhirDataTypesMod.Coding, index: number) => {
                  return getCodeableConceptRender(
                    "stratum.value.coding.[" + index + "]",
                    value
                  );
                }
              )}

              {stratum.component?.map(
                (item: FhirMeasureReportMod.Component, index: number) => {
                  return getComponentRender(index, item);
                }
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  };

  const getStratifiersRender = (
    stratifiers: FhirMeasureReportMod.Stratifier[],
    onClickRiskCompMainText: () => void
  ): JSX.Element[] | undefined => {
    let stratums: JSX.Element[] = [];

    stratifiers.forEach((value: FhirMeasureReportMod.Stratifier) => {
      value.stratum?.forEach((value2: FhirMeasureReportMod.Stratum) => {
        stratums.push(getStratumRender(value2, onClickRiskCompMainText));
      });
    });

    return stratums;
  };

  const getRenderGroup = (group: FhirMeasureReportMod.Group) => {
    const onClickRiskCompMainText = () => {
      if (group.code?.coding?.[0].code !== undefined) {
        const questResSplitRef = getSplitReference(
          group.code?.coding?.[0].code
        );
        onClickMeasureGroup(questResSplitRef.uuid);
      }
    };

    const stratums =
      group.stratifier !== undefined
        ? getStratifiersRender(group.stratifier, onClickRiskCompMainText)
        : [];

    const questResSplitRef =
      group.code?.coding?.[0].code === undefined
        ? undefined
        : getSplitReference(group.code?.coding[0].code);

    const questRes =
      questResSplitRef?.uuid === undefined
        ? undefined
        : indexedfhirQuestionnaireResponses[questResSplitRef?.uuid];

    const matchingQuestUuidFromUrl = (() => {
      if (questRes?.questionnaire === undefined) return undefined;

      const url = questRes?.questionnaire;
      const regex1 = /(Questionnaire\/.+)/g;
      const matchedValues = url.match(regex1);

      if (matchedValues?.[0] !== undefined) {
        return getSplitReference(matchedValues[0]).uuid;
      }

      return undefined;
    })();

    const quest =
      matchingQuestUuidFromUrl === undefined
        ? undefined
        : indexedFhirQuestionnaires[matchingQuestUuidFromUrl];

    return (
      <Grid item md={12}>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Grid container item direction="row" md={12}>
              <Grid container item md={12}>
                <Grid container item md={4} alignItems="center">
                  <Typography>
                    {group.code?.text === undefined ? "" : group.code?.text}
                  </Typography>
                </Grid>
              </Grid>
              {devMode && (
                <Grid
                  className={classes.highlight}
                  container
                  item
                  md={12}
                  direction="column"
                >
                  <KeyValueGrid
                    label="group.code.text"
                    value={
                      group.code?.text === undefined ? "" : group.code?.text
                    }
                  />
                  <KeyValueGrid
                    label="group.code.coding.[0].code"
                    value={
                      group.code?.coding?.[0].code === undefined
                        ? ""
                        : group.code?.coding?.[0].code
                    }
                  />
                </Grid>
              )}
            </Grid>
          </AccordionSummary>
          <Grid
            container
            spacing={1}
            paddingLeft={2}
            paddingRight={4}
            paddingBottom={2}
          >
            {stratums?.map((item: JSX.Element, index: number) => {
              return (
                <Grid item md={12}>
                  {item}
                </Grid>
              );
            })}
          </Grid>

          {showMeasureQuestionnaireResponses && questRes && quest && (
            <QuestRes
              questionnaire={quest}
              questionnaireResponse={questRes}
              devMode={devMode}
            />
          )}
        </Accordion>
      </Grid>
    );
  };

  const getRenderIndicator = (displayText: string, risk: Risks) => {
    return (
      <Grid
        container
        item
        justifyContent="center"
        alignItems="center"
        className={getRiskColor(risk).normalClass}
        style={{
          height: 32,
        }}
        paddingLeft={2}
        paddingRight={2}
      >
        <Typography>
          <b>{displayText}</b>
        </Typography>
      </Grid>
    );
  };

  const getRenderComplexScoreGroup = (
    mainText: string,
    group: FhirMeasureReportMod.Group,
    riskCode?: Risks
  ) => {
    const measureValue = group.stratifier?.[0].stratum?.[0].measureScore?.value;
    const riskValue =
      group.stratifier?.[0]?.stratum?.[0]?.component?.[0]?.code?.coding?.[0]
        ?.code;
    const riskCodeParsed =
      riskCode === undefined
        ? riskValue === undefined
          ? "none"
          : getRisk(riskValue)
        : riskCode;
    const indicatorText = "Value " + measureValue;
    return (
      <Paper elevation={3}>
        <Grid container direction="row" justifyContent="center" padding={2}>
          <Grid container item md={12} justifyContent="center" marginBottom={3}>
            <Typography variant="h5">{mainText}</Typography>
          </Grid>

          <Grid item md={12}>
            {getRenderIndicator(indicatorText, riskCodeParsed)}
          </Grid>
          {devMode && (
            <Grid
              className={classes.highlight}
              container
              item
              md={12}
              direction="column"
            >
              <KeyValueGrid
                label="group.stratifier[0].stratum[0].measureScore.value"
                value={
                  group.stratifier?.[0].stratum?.[0].measureScore?.value ===
                  undefined
                    ? ""
                    : String(
                        group.stratifier?.[0].stratum?.[0].measureScore?.value
                      )
                }
              />
              <KeyValueGrid
                label="group.stratifier.[0].stratum.[0].component.[0].code.coding[0].code"
                value={
                  group.stratifier?.[0]?.stratum?.[0]?.component?.[0]?.code
                    ?.coding?.[0]?.code === undefined
                    ? ""
                    : group.stratifier?.[0]?.stratum?.[0]?.component?.[0]?.code
                        ?.coding?.[0]?.code
                }
              />
              <KeyValueGrid label="riskCodeParsed" value={riskCodeParsed} />
            </Grid>
          )}
        </Grid>
      </Paper>
    );
  };

  const getRenderMedicalComplexGroup = (group: FhirMeasureReportMod.Group) => {
    const measureValue = group.stratifier?.[0].stratum?.[0].measureScore?.value;
    let riskCode: Risks = "none";

    if (measureValue !== undefined) {
      switch (measureValue) {
        case 1:
          riskCode = "low-risk";
          break;
        case 2:
          riskCode = "medium-risk";
          break;
        case 3:
          riskCode = "high-risk";
          break;
        default:
          riskCode = "none";
      }
    }

    return getRenderComplexScoreGroup("Medical complexity", group, riskCode);
  };

  const getRenderServiceIntegrationGroup = (
    group: FhirMeasureReportMod.Group
  ) => {
    const measureValue = group.stratifier?.[0].stratum?.[0].measureScore?.value;
    let riskCode: Risks = "none";

    if (measureValue !== undefined) {
      switch (measureValue) {
        case 1:
          riskCode = "low-risk";
          break;
        case 2:
          riskCode = "medium-risk";
          break;
        case 3:
          riskCode = "high-risk";
          break;
        default:
          riskCode = "none";
      }
    }

    return getRenderComplexScoreGroup(
      "Service integration levels",
      group,
      riskCode
    );
  };

  const mrGroups: MeasureReportGroups = getMeasureReportGroups(measureReport);

  interface ColorIndicators {
    text: string;
    riskValue: Risks;
    flex: GridSize;
  }

  const indicatorsList: ColorIndicators[] = [
    { text: "None", riskValue: "none", flex: 2 },
    { text: "Low Risk", riskValue: "low-risk", flex: 2 },
    { text: "Medium Risk", riskValue: "medium-risk", flex: 2 },
    { text: "High Risk", riskValue: "high-risk", flex: 2 },
    { text: "Indeterminate", riskValue: "indeterminate-risk", flex: 2 },
  ];

  return (
    <Grid item md={12} container spacing={3}>

      {mrGroups.serviceIntegration && (
        <Grid item md={4}>
          {getRenderServiceIntegrationGroup(mrGroups.serviceIntegration)}
        </Grid>
      )}

      <Grid item md={12}>
        <Paper elevation={3}>
          <Grid container direction="row" justifyContent="center">
            <Grid container item md={12} style={{ padding: 16 }}>
              <Grid
                container
                item
                flexDirection="row"
                justifyContent="space-around"
                style={{ marginBottom: 16 }}
              >
                {indicatorsList.map((item: ColorIndicators) => {
                  return (
                    <Grid item md={item.flex}>
                      {getRenderIndicator(item.text, item.riskValue)}
                    </Grid>
                  );
                })}
              </Grid>

              <Grid
                container
                item
                md={12}
                flexDirection="row"
                alignItems="center"
                spacing={2}
              >
                {devMode && (
                  <Grid container item md={12} direction="row">
                    <Grid
                      className={classes.highlight}
                      container
                      item
                      md={12}
                      direction="column"
                    >
                      {measureReport.id && (
                        <KeyValueGrid
                          label={"measureReport.id"}
                          value={
                            measureReport.id === undefined
                              ? ""
                              : measureReport.resourceType +
                                "/" +
                                measureReport.id
                          }
                        />
                      )}
                      {measureReport.measure && (
                        <KeyValueGrid
                          label={"measureReport.measure"}
                          value={
                            measureReport.measure === undefined
                              ? ""
                              : measureReport.measure
                          }
                        />
                      )}
                    </Grid>
                  </Grid>
                )}

                {mrGroups.normal?.map((group: FhirMeasureReportMod.Group) => {
                  return getRenderGroup(group);
                })}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
