import axios from "axios";
import { INoteRowInfo } from "components/patient-engagement/NotesList";
import { FhirPlanDefinitionMod } from "models/fhirPlanDefinition";
import {
  FetchFhirPaginationFn,
  FhirPagination,
  FhirPatientPaginationSort,
  FhirServiceRequestPaginationSort,
  IndexedServiceRequestWithTasksNPatientNOrg,
  IndexedTasksWithServiceRequestsAndOwner,
  QueryServiceRequestWithTasks,
  QueryServiceRequestWithTasksNPatientNOrg,
} from "models/fhirQuery";
import {
  getlistResource,
  getPaginatedResource,
  indexQueryServiceRequestWithTasksNPatientNOrg,
  indexResource,
  indexQueryTask,
} from "models/fhirQueryFn";
import { FhirMeasureReportMod } from "models/measureReport";
import {
  AssessmentsResponse,
  OrderDetailsResponse,
  TaskMod,
} from "models/order";
import {
  FhirOrganizationMod,
  HealthcareOrganizationListItemMod,
  HealthcareOrganizationMod,
} from "models/organization";
import { FhirPatientMod, HealthcarePatient } from "models/patient";
import { FhirPractitionerMod } from "models/practitioner";
import { FhirQuestionnaireMod } from "models/questionnaire";
import { FhirQuestionnaireResponseMod } from "models/questionnaireResponse";
import { FhirRelatedPersonMod } from "models/relatedPerson";
import { FhirServiceRequestMod } from "models/serviceRequest";
import { FhirTaskMod } from "models/task";
import { HasOrganizationData, HasOrganizationResponse } from "models/user";
import { FhirValueSetMod } from "models/valueSet";
import { FhirConsent } from "models/fhirConsent";
import { urlBackNestApp } from "routes/urls";
import { getFhirUserOrganizationsIds, getUserOrgAndChilds } from 'utils/fhirUtils';

let defaultHeaders = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${localStorage.getItem("encryptUser")}`,
};

export function getDefaultHeaders() {
  if (defaultHeaders.Authorization == "Bearer null") {
    console.log("getting token from storage");
    defaultHeaders.Authorization = `Bearer ${localStorage.getItem(
      "encryptUser"
    )}`;
  }
  return defaultHeaders;
}

async function get<T>(
  path: string,
  args: RequestInit = { method: "GET", headers: getDefaultHeaders() }
): Promise<T> {
  const time1 = performance.now();
  const response = await fetch(path, args);
  const finalResponse = response.json();
  const time2 = performance.now();

  console.log(`${((time2 - time1) / 1000).toFixed(2)}s in response this call ${path}`)

  return finalResponse;
}

async function post<T>(
  path: string,
  body: any,
  args: RequestInit = {
    method: "POST",
    body: JSON.stringify(body),
    headers: getDefaultHeaders(),
  }
): Promise<T> {
  const response = await fetch(path, args);
  return response.json();
}

async function put<T>(
  path: string,
  body: any,
  args: RequestInit = {
    method: "PUT",
    body: JSON.stringify(body),
    headers: getDefaultHeaders(),
  }
): Promise<T> {
  const response = await fetch(path, args);
  return response.json();
}

async function patch<T>(
  path: string,
  body: any,
  args: RequestInit = {
    method: "PATCH",
    body: JSON.stringify(body),
    headers: getDefaultHeaders(),
  }
): Promise<T> {
  const response = await fetch(path, args);
  return response.json();
}

const FhirPractitioner = {
  /* list: ():  =>  */
  details: (uuid: string) =>
    get<FhirPractitionerMod.Practitioner>(
      `${urlBackNestApp}/fhirproxy/Practitioner/${uuid}`
    ),
  /* create: () => ,
    update: () => ,
    delete: () =>  */
};

const FhirOrganization = {
  details: (uuid: string) =>
    get<FhirOrganizationMod.Organization>(
      `${urlBackNestApp}/fhirproxy/Organization/${uuid}`
    ),
};

const fhirPatientPaginatedList: FetchFhirPaginationFn<
  FhirPatientMod.Patient,
  FhirPatientPaginationSort
> = async (
  pageCount: string = "25",
  sortList?: FhirPatientPaginationSort[],
  filter?: string,
  pageToken?: string,
  user?: any, 
  active?:any,
): Promise<FhirPagination<FhirPatientMod.Patient>> => {
  const getOrgsFromUser = await getUserOrgAndChilds(user);
  const userOrganizations = await getFhirUserOrganizationsIds(getOrgsFromUser);

  let fetchUrl = `${urlBackNestApp}/fhirproxy/Patient/?_count=${pageCount}`;

  if (sortList !== undefined && sortList.length > 0) {
    let sortStr = "&_sort=";
    sortList.forEach((item: FhirPatientPaginationSort) => {
      if (item.desc) sortStr += "-";

      sortStr += item.value;
    });

    fetchUrl += sortStr;
  }

  fetchUrl = `${urlBackNestApp}/fhirproxy/Patient?organization=${userOrganizations.join()}&_include:iterate=Patient:organization&_count=${pageCount}`;

  if (filter !== undefined && filter !== "") fetchUrl += `&_content=${filter}`;

  if (pageToken !== undefined && pageToken!='false') fetchUrl += `&_page_token=${pageToken}`;

  if (active !== undefined) fetchUrl += `&active=${active}`;
  // console.log('fetchUrl', fetchUrl);

  const response = await get<any>(fetchUrl);

  const resources = getlistResource<FhirPatientMod.Patient>(
    response,
    "Patient"
  );

  return getPaginatedResource<FhirPatientMod.Patient>(response, resources);
};

const FhirPatient = {
  paginatedList: fhirPatientPaginatedList,
  details: (uuid: string) =>
    get<FhirPatientMod.Patient>(`${urlBackNestApp}/fhirproxy/Patient/${uuid}`),
  detailsWithOrg: (uuid: string) =>
    get<any>(
      `${urlBackNestApp}/fhirproxy/Patient/?_id=${uuid}&_include=Patient:organization`
    ),
  updatePatientIdentifier: (uuid: string, data: any = {}) =>
    put<FhirPatientMod.Patient>(`${urlBackNestApp}/healthcare/patient/${uuid}/identifier`, data),
  updatePatientToEnroll: (uuid: string, data: any = {}) =>
    put<FhirPatientMod.Patient>(`${urlBackNestApp}/healthcare/patient/${uuid}/enroll`, data),
  printableInfo: (uuid: string) =>
    get<any>(`${urlBackNestApp}/fhirproxy/Patient/printableinfo/${uuid}`),
};

const fhirServiceRequestPaginatedNIndexedListWithTasksNPatientNOrg: FetchFhirPaginationFn<
  IndexedServiceRequestWithTasksNPatientNOrg,
  FhirServiceRequestPaginationSort
> = async (
  pageCount: string = "25",
  sortList?: FhirServiceRequestPaginationSort[],
  filter?: string,
  pageToken?: string,
  status?: string,
  user?: any,
): Promise<FhirPagination<IndexedServiceRequestWithTasksNPatientNOrg>> => {
  const getOrgsFromUser = await getUserOrgAndChilds(user);
  const userOrganizations = await getFhirUserOrganizationsIds(getOrgsFromUser);

  let fetchUrl = `${urlBackNestApp}/fhirproxy/ServiceRequest/?_count=${pageCount}`;

  // filter removed service request
  // fetchUrl += "&status:not=revoked";

  if (status !== undefined) fetchUrl += `&status=${status}`;

  if (sortList !== undefined && sortList.length > 0) {
    let sortStr = "&_sort=";
    sortList.forEach((item: FhirServiceRequestPaginationSort) => {
      if (item.desc) sortStr += "-";

      sortStr += item.value;
    });

    fetchUrl += sortStr;
  }

  // fetchUrl = `${urlBackNestApp}/fhirproxy/ServiceRequest/?requester=${userOrganizations.join()}&status:not=revoked`;

  fetchUrl = `${urlBackNestApp}/fhirproxy/ServiceRequest/?requester=${userOrganizations.join()}`;

  fetchUrl += `&_revinclude=Task:based-on:ServiceRequest&_include=ServiceRequest:subject:Patient&_include:iterate=Patient:organization&_count=${pageCount}`;

  if (filter !== undefined && filter !== "") fetchUrl += `&_content=${filter}`;

  if (pageToken !== undefined) fetchUrl += `&_page_token=${pageToken}`;

  console.log('fetchUrl for serviceRequest', fetchUrl);

  const response = await get<any>(fetchUrl);

  const indexedResources = indexQueryServiceRequestWithTasksNPatientNOrg(
    response
  );

  return getPaginatedResource<IndexedServiceRequestWithTasksNPatientNOrg>(
    response,
    indexedResources
  );
};

const getTasksList = async (
  pageCount: string = "25",
  sortList?: FhirServiceRequestPaginationSort[],
  filter?: string,
  pageToken?: string,
  status?: string,
  user?: any
): Promise<any> => {
  const getOrgsFromUser = await getUserOrgAndChilds(user);
  const userOrganizations = await getFhirUserOrganizationsIds(getOrgsFromUser);
  let fetchUrl = `${urlBackNestApp}/fhirproxy/Task/?code=completed-nat-assessment&requester=${userOrganizations.join()}&_include:iterate=Task:owner&_include:iterate=Task:based-on&_include:iterate=ServiceRequest:subject&_count=${pageCount}`;

  // filter removed service request
  // fetchUrl += "&status:not=revoked";

  if (status !== undefined && status !=='') fetchUrl += `&status=${status}`;

  if (sortList !== undefined && sortList.length > 0) {
    let sortStr = "&_sort=";
    sortList.forEach((item: FhirServiceRequestPaginationSort) => {
      if (item.desc) sortStr += "-";

      sortStr += item.value;
    });

    fetchUrl += sortStr;
  }

  if (filter !== undefined && filter !== "") fetchUrl += `&_content=${filter}`;

  if (pageToken !== undefined && pageToken!='false') fetchUrl += `&_page_token=${pageToken}`;

  const response = await get<any>(fetchUrl);

  const fetchPlanDefinition = `${urlBackNestApp}/fhirproxy/PlanDefinition`;

  const response2 = await get<any>(fetchPlanDefinition);

  const finalResponse = {
    ...response,
    entry: response?.entry?.concat(response2?.entry),
  };

  const indexedResources = indexQueryTask(
    finalResponse
  );

  return getPaginatedResource<any>(
    response,
    indexedResources
  );
};

const FhirServiceRequest = {
  details: (uuid: string) =>
    get<FhirServiceRequestMod.ServiceRequest>(
      `${urlBackNestApp}/fhirproxy/ServiceRequest/${uuid}`
    ),
  taskslist: getTasksList,
  detailsWithTasks: async (
    uuid: string
  ): Promise<QueryServiceRequestWithTasks> => {
    const response = await put<any>(
      `${urlBackNestApp}/fhirproxy/compositeread?query=ServiceRequest?_id=${uuid}%26_revinclude=Task:based-on:ServiceRequest`,
      {}
    );

    const serviceRequest = response.entry.find(
      (item: any) =>
        item.resource.resourceType === "ServiceRequest" &&
        item.search.mode === "match"
    ).resource as FhirServiceRequestMod.ServiceRequest;

    const tasks = response.entry
      .filter(
        (item: any) =>
          item.resource.resourceType === "Task" &&
          item.search.mode === "include"
      )
      .map((item: any) => item.resource) as FhirTaskMod.Task[];

    const queryServiceRequestWithTasks: QueryServiceRequestWithTasks = {
      serviceRequest: serviceRequest,
      tasks: tasks,
    };

    return queryServiceRequestWithTasks;
  },
  allWithTasksNPatientNOrg: async (): Promise<QueryServiceRequestWithTasksNPatientNOrg> => {
    const response = await get<any>(
      `${urlBackNestApp}/fhirproxy/ServiceRequest?_revinclude=Task:based-on:ServiceRequest&_include=ServiceRequest:subject:Patient&_include:iterate=Patient:organization`
    );

    const queryServiceRequestWithTasksNPatientNOrg: QueryServiceRequestWithTasksNPatientNOrg = {
      serviceRequests: [],
      tasks: [],
      patients: [],
      organizations: [],
    };

    response.entry.forEach((item: any) => {
      if (
        item.resource.resourceType === "ServiceRequest" &&
        item.search.mode === "match"
      ) {
        const serviceRequest = item.resource as FhirServiceRequestMod.ServiceRequest;
        queryServiceRequestWithTasksNPatientNOrg.serviceRequests.push(
          serviceRequest
        );
      } else if (
        item.resource.resourceType === "Task" &&
        item.search.mode === "include"
      ) {
        const task = item.resource as FhirTaskMod.Task;
        queryServiceRequestWithTasksNPatientNOrg.tasks.push(task);
      } else if (
        item.resource.resourceType === "Patient" &&
        item.search.mode === "include"
      ) {
        const patient = item.resource as FhirPatientMod.Patient;
        queryServiceRequestWithTasksNPatientNOrg.patients.push(patient);
      } else if (
        item.resource.resourceType === "Organization" &&
        item.search.mode === "include"
      ) {
        const organization = item.resource as FhirOrganizationMod.Organization;
        queryServiceRequestWithTasksNPatientNOrg.organizations.push(
          organization
        );
      }
    });

    return queryServiceRequestWithTasksNPatientNOrg;
  },
  indexedAllWithTasksNPatientNOrg: async (
    filterstatus: any
  ): Promise<IndexedServiceRequestWithTasksNPatientNOrg> => {
    const response = await get<any>(
      `${urlBackNestApp}/fhirproxy/ServiceRequest?_count=500&status=${filterstatus}&_revinclude=Task:based-on:ServiceRequest&_include=ServiceRequest:subject:Patient&_include:iterate=Patient:organization`
    );

    return indexQueryServiceRequestWithTasksNPatientNOrg(response);
  },
  paginatedNIndexedListWithTasksNPatientNOrg: fhirServiceRequestPaginatedNIndexedListWithTasksNPatientNOrg,
  listWhereSubjectPatient: async (patientUuid: string) => {
    const response = await get<any>(
      `${urlBackNestApp}/fhirproxy/ServiceRequest?subject=${patientUuid}`
    );

    return getlistResource<FhirServiceRequestMod.ServiceRequest>(
      response,
      "ServiceRequest"
    );
  },
};

const FhirTask = {
  details: (uuid: string) =>
    get<FhirTaskMod.Task>(`${urlBackNestApp}/fhirproxy/Task/${uuid}`),
  detailsWithServiceRequest: (uuid: string) =>
    get<FhirTaskMod.Task>(`${urlBackNestApp}/fhirproxy/Task/?_id=${uuid}&_include:iterate=Task:based-on`),
  byPatientWithServiceRequestAndOwner: async (
    patientUuid: string
  ): Promise<IndexedTasksWithServiceRequestsAndOwner> => {
    const response = await get<any>(
      `${urlBackNestApp}/fhirproxy/Task?patient=${patientUuid}&_include:iterate=Task:based-on:ServiceRequest&_include:iterate=Task:owner&_has:ServiceRequest:status:not=completed&_has:ServiceRequest:status:not=revoked`
    );

    let indexedTasksWithServiceRequestsAndOwner: IndexedTasksWithServiceRequestsAndOwner = {
      serviceRequests: {},
      tasks: {},
      patientOwner: {},
      relatedPersonOwner: {},
    };

    const removeTaskAndServiceRequestWithStatus = (entries: any = []) => {
      const serviceRequests = entries.filter((entry: any) => entry.resource.resourceType === "ServiceRequest");
      const removeCompletedAndRevokedStatus = serviceRequests.filter((item: any) => item.resource.status === "revoked" || item.resource.status === "completed");
      return removeCompletedAndRevokedStatus.map((item: any) => `ServiceRequest/${item.resource.id}`);
    }

    const serviceRequestToRemove = removeTaskAndServiceRequestWithStatus(response?.entry);

    response?.entry?.forEach((item: any) => {
      if (
        item.resource.resourceType === "Task" &&
        item.search.mode === "match"
      ) {
        const casted = item.resource as FhirTaskMod.Task;
        const shouldRemoveTask = casted?.basedOn?.some((item: any) => serviceRequestToRemove.includes(item.reference));
        if(!shouldRemoveTask) {
          indexedTasksWithServiceRequestsAndOwner.tasks[`${casted.id}`] = casted;
        }
      } else if (
        item.resource.resourceType === "ServiceRequest" &&
        item.search.mode === "include"
      ) {
        const casted = item.resource as FhirServiceRequestMod.ServiceRequest;
        const shouldRemoveServiceRequest = serviceRequestToRemove.includes(`ServiceRequest/${casted.id}`);
        if(!shouldRemoveServiceRequest) {
          indexedTasksWithServiceRequestsAndOwner.serviceRequests[`${casted.id}`] = casted;
        }
      } else if (
        item.resource.resourceType === "Patient" &&
        item.search.mode === "include"
      ) {
        const casted = item.resource as FhirPatientMod.Patient;
        indexedTasksWithServiceRequestsAndOwner.patientOwner[
          `${casted.id}`
        ] = casted;
      } else if (
        item.resource.resourceType === "RelatedPerson" &&
        item.search.mode === "include"
      ) {
        const casted = item.resource as FhirRelatedPersonMod.RelatedPerson;
        indexedTasksWithServiceRequestsAndOwner.relatedPersonOwner[
          `${casted.id}`
        ] = casted;
      }
    });

    return indexedTasksWithServiceRequestsAndOwner;
  },
};

const FhirQuestionnaire = {
  list: async (uuids: string[]) => {
    const response = await get<any>(
      `${urlBackNestApp}/fhirproxy/Questionnaire?_id=${uuids.join(",")}`
    );

    return getlistResource<FhirQuestionnaireResponseMod.QuestionnaireResponse>(
      response,
      "Questionnaire"
    );
  },
  indexedList: async (uuids: string[]) => {
    const response = await get<any>(
      `${urlBackNestApp}/fhirproxy/Questionnaire?_id=${uuids.join(",")}`
    );

    return indexResource<FhirQuestionnaireMod.Questionnaire>(
      response,
      "Questionnaire"
    );
  },
};

const FhirQuestionnaireResponse = {
  list: async (uuids: string[]) => {
    const response = await get<any>(
      `${urlBackNestApp}/fhirproxy/QuestionnaireResponse?_id=${uuids.join(",")}`
    );

    return getlistResource<FhirQuestionnaireResponseMod.QuestionnaireResponse>(
      response,
      "QuestionnaireResponse"
    );
  },
  indexedList: async (uuids: string[]) => {
    const response = await get<any>(
      `${urlBackNestApp}/fhirproxy/QuestionnaireResponseFilter?_id=${uuids.join(
        ","
      )}` // &_security:not=R
    );

    return indexResource<any>(response, "QuestionnaireResponse");
  },
  indexedListWithSecurity: async (uuids: string[], securityLabel: any) => {
    const response = await get<any>(
      `${urlBackNestApp}/fhirproxy/QuestionnaireResponseFilter?_id=${uuids.join(
        ","
      )}&_security:not=${securityLabel}` // &_security:not=R
    );

    return indexResource<FhirQuestionnaireResponseMod.QuestionnaireResponse>(
      response,
      "QuestionnaireResponse"
    );
  },
};

const FhirMeasureReport = {
  details: (uuid: string) =>
    get<FhirMeasureReportMod.MeasureReport>(
      `${urlBackNestApp}/fhirproxy/MeasureReport/${uuid}`
    ),
};

const FhirObservation = {
  bySubject: (uuid: string) =>
    get<any>(`${urlBackNestApp}/fhirproxy/Observation/?subject=${uuid}`),
  getByPatientCode: (uuid: string, code: string) =>
    get<any>(`${urlBackNestApp}/fhirproxy/Observation?subject=${uuid}&status:not=cancelled&code=${code}`),
    getByPatientArray:(arrayPatient: any, code: string = 'sil') =>
    get<any>(`${urlBackNestApp}/fhirproxy/Observation?subject=${arrayPatient.join(',')}&status:not=cancelled&code=${code}&_include:missing=false`),
  getByPatientArrayNextPage:(subject: any, code: string , token :any) =>
    get<any>(`${urlBackNestApp}/fhirproxy/Observation?subject=${subject}&status:not=cancelled&code=${code}&_include:missing=false&_page_token=${token}`),
};

const FhirRelatedPerson = {
  details: (uuid: string) =>
    get<FhirRelatedPersonMod.RelatedPerson>(
      `${urlBackNestApp}/fhirproxy/RelatedPerson/${uuid}`
    ),
};

const FhirPlanDefinition = {
  details: (uuid: string) =>
    get<FhirPlanDefinitionMod.PlanDefinition>(
      `${urlBackNestApp}/fhirproxy/PlanDefinition/${uuid}`
    ),
};

const Order = {
  details: (id: string) =>
    get<OrderDetailsResponse>(`${urlBackNestApp}/order/${id}`),
  getTask: (patientId: string) =>
    get<TaskMod.TaskResponse>(`${urlBackNestApp}/order/task/${patientId}`),
  getAssessment: (taskId: string) =>
    get<AssessmentsResponse.Assessment>(
      `https://dev-natweb.myctinck.com/api/order/assesment/${taskId}`
    ),
  resendTask: (taskId: string) =>
    post<TaskMod.TaskResponse>(
      `${urlBackNestApp}/order/task/${taskId}/resend`,
      {}
    ),
  getPrintable: async (printableTaskData: PrintableTaskMod.Task) => {
    const response = await axios.post<any>(
      `${urlBackNestApp}/order/printable`,
      printableTaskData,
      { responseType: "arraybuffer", timeout: 20000 }
    );
    return response;
  },
  reprocess: (taskId: string) =>
    post<TaskMod.TaskResponse>(
      `${urlBackNestApp}/order/task/${taskId}/reprocess`,
      {}
    ),
};

const Communication = {
  addNote: (data: any) =>
    post<any>(`${urlBackNestApp}/fhirproxy/Communication`, data),
  getNote: (patientId: string) =>
    get<any>(`${urlBackNestApp}/fhirproxy/Communication/?subject=${patientId}`),
  updateNote: (data: any) =>
    patch<any>(`${urlBackNestApp}/fhirproxy/Communication/${data.id}`, data),
};

const Binary = {
  add: (data: any) => post<any>(`${urlBackNestApp}/fhirproxy/Binary`, data),
};

const DocumentReference = {
  add: (data: any) =>
    post<any>(`${urlBackNestApp}/fhirproxy/DocumentReference`, data),
};

const Patient = {
  allpatients: () =>
    get<HealthcarePatient[]>(
      `${urlBackNestApp}/healthcare/patient/allpatients`
    ),
  fhirPatch: (patient: FhirPatientMod.Patient) => {
    if (patient.id === undefined) return;

    return patch<any>(
      `${urlBackNestApp}/healthcare/patient/fhir/${patient.id}`,
      patient
    );
  },
  get: (id: string) =>
    get<HealthcarePatient>(`${urlBackNestApp}/healthcare/patient/${id}`),
};

const Organization = {
  list: () =>
    get<HealthcareOrganizationListItemMod.Organization[]>(
      `${urlBackNestApp}/healthcare/allorganizations`
    ),
  get: (id: string) =>
    get<HealthcareOrganizationMod.Organization>(
      `${urlBackNestApp}/healthcare/organization/${id}`
    ),
};

const Healthcare = {
  Patient: Patient,
  Organization: Organization,
  Consent: {
    ConsentByPatient: (resource: string, id: string) => get<any>(`${urlBackNestApp}/fhirproxy/${resource}/?patient=${id}`)
  }
};

const User = {
  allUser: () => get<any>(`${urlBackNestApp}/users/all-users`),
  hasOrganization: (data: HasOrganizationData) =>
    post<HasOrganizationResponse>(
      `${urlBackNestApp}/users/has-organization`,
      data
    ),
  getInfo: (email: any) => get<any>(`${urlBackNestApp}/users/${email}`),
  getAclsAndRole: (username: any) => get<any>(`${urlBackNestApp}/users/user-acl-role/${username}`),
};

const FhirPatientConsent = {
  details: (uuid: string) =>
    get<FhirConsent.Consent>(`${urlBackNestApp}/fhirproxy/consent/${uuid}`),
  byPatient: (patientid: string) =>
    get<FhirConsent.Consent>(
      `${urlBackNestApp}/fhirproxy/Consent/?patient=${patientid}`
    ),
};

const FhirValueSet = {
  details: (id: string) =>
    get<FhirValueSetMod.ValueSet>(`${urlBackNestApp}/fhirproxy/ValueSet/${id}`),
};

const Workflow = {
  getWorkflowByCode: (code: any) =>
    get<any>(`${urlBackNestApp}/workflow/?code=${code}`),
  getTransitions: (workFlow: any) =>
    get<any>(`${urlBackNestApp}/workflow/transitions?workFlow=${workFlow}`),
};

const Checklist = {
  getChecklistById: (data: any) =>
    post<any>(`${urlBackNestApp}/checklist/`, data),
};

const Transition = {
  attempt: (data: any) =>
    post<any>(`${urlBackNestApp}/transition/attempt/`, data),
  updateStage: (data: any) =>
    put<any>(`${urlBackNestApp}/transition/stage/`, data),
};

const PatientEngagement = {
  getPatientById: (id: any) =>
    get<any>(`${urlBackNestApp}/patientengagement/${id}`),
  getPatientByEmail: (email: any) =>
    get<any>(`${urlBackNestApp}/patientengagement/email/${email}`),
  getPatientByFhirId: (id: any) =>
    get<any>(`${urlBackNestApp}/patientengagement/fhir/${id}`),
  getPatientContactAttempt: (id: any) =>
    get<any>(
      `${urlBackNestApp}/patientengagement/contact-attempts?patient=${id}`
    ),
  createPatientEngagement: (data: any) =>
    post<any>(`${urlBackNestApp}/patientengagement/new`, data),
  updatePatientById: (id: any, data: any) =>
    put<any>(`${urlBackNestApp}/patientengagement/${id}`, data),
  customUpdate: (data: any) =>
    post<any>(`${urlBackNestApp}/patientengagement/customUpdate`, data),
  checkitem: (data: any) =>
    post<any>(`${urlBackNestApp}/patientengagement/checkitem`, data),
  postNote: (data: INoteRowInfo) =>
    post<INoteRowInfo>(`${urlBackNestApp}/notes/patient`, data),
  getNotes: async (patientEngagementId: string): Promise<INoteRowInfo[]> => {
    const response = await get<any>(
      `${urlBackNestApp}/notes/patient/${patientEngagementId}`
    );

    if (response.statusCode === 404) return [];

    return response as INoteRowInfo[];
  },
  savePatientContactAttempt: (data: any) =>
    post<any>(`${urlBackNestApp}/patientengagement/contact-attempts/`, data),
  complementaryInfo: (data: any) =>
    post<any>(`${urlBackNestApp}/patientengagement/complementary-info/`, data),
  bulkAssign: (data: any) =>
    post<any>(`${urlBackNestApp}/patientengagement/bulkAssign`, data),
  markContactAttemptAsCompleted: (data: any) =>
    post<any>(`${urlBackNestApp}/patientengagement/contact-attempts/completed`, data),
};

const Catalog = {
  getByCode: (code: string) => get<any>(`${urlBackNestApp}/catalog/${code}`),
};

const defaultConsts = {
  FhirRelatedPerson,
  FhirMeasureReport,
  FhirObservation,
  FhirPractitioner,
  FhirPatient,
  FhirTask,
  FhirOrganization,
  FhirQuestionnaire,
  FhirQuestionnaireResponse,
  FhirPlanDefinition,
  FhirServiceRequest,
  FhirPatientConsent,
  FhirValueSet,
  Order,
  Communication,
  Binary,
  DocumentReference,
  Healthcare,
  User,
  Workflow,
  Checklist,
  Transition,
  PatientEngagement,
  Catalog,
  Patient,
};

export default defaultConsts;
