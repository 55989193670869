function getValueFromSystemAsKey(list: any, system: string | undefined) {
  if (list === undefined || system === undefined) return undefined;

  return list.find((value: any) => {
    const keys = Object.keys(value);

    if (keys && keys.length > 0) {
      return keys[0] === system;
    }

    return false;
  })?.[system]?.[0];
}

function getValueSystem(list: any, system: string | undefined) {
  if (list === undefined || system === undefined) return undefined;

  return list.find((item: any) => {
    return item.system === system;
  })?.value;
}

export function getMRNFromHealthcare(list: any) {
  const system = process.env.REACT_APP_SYSTEM_MRN;
  return getValueFromSystemAsKey(list, system);
}

export function getBirthCertificateNumberFromHealthcare(list: any) {
  const system = process.env.REACT_APP_SYSTEM_BIRTH_CERTIFICATE_NUMBER;
  return getValueSystem(list, system);
}

export function getDriverLicenseNumberFromHealthcare(list: any) {
  const system = process.env.REACT_APP_DRIVER_LICENSE_NUMBER;
  return getValueSystem(list, system);
}

export function getSocialSecurityNumberFromHealthcare(list: any) {
  const system = process.env.REACT_APP_SOCIAL_SECURITY_NUMBER;
  return getValueSystem(list, system);
}

export function getServiceRequestNumberFromHealthcare(list: any) {
  const system = process.env.REACT_APP_SYSTEM_SERVICE_REQUEST_NUMBER;
  return getValueSystem(list, system);
}

export function getTaskNumberFromHealthcare(list: any) {
  const system = process.env.REACT_APP_SYSTEM_TASK_NUMBER;
  return getValueSystem(list, system);
}

export function getKeycloakIDFromHealthcare(list: any) {
  const system = process.env.REACT_APP_SYSTEM_KEYCLOAK_ID;
  return getValueSystem(list, system);
}

export function getNPIFromHealthcare(list: any) {
  const system = process.env.REACT_APP_SYSTEM_NPI;
  return getValueSystem(list, system);
}

export function getFamilyIdentifier(list: any) {
  const system = process.env.REACT_APP_SYSTEM_FAMILY_IDENTIFIER;
  return getValueFromSystemAsKey(list, system);
}