import { TextField } from "@material-ui/core";

const Input = (props: any) => {
  return (
    <TextField
      fullWidth
      id={`outlined-${props?.name || props?.elementName}`}
      label={props?.name || props?.elementName}
      onChange={props?.onChange}
    />
  );
};

export default Input;
