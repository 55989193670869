/* import { Grid, Typography } from "@material-ui/core/"; */
import { makeStyles } from "@material-ui/core/styles";
/* import Feedback from "./Feedback"; */

const useStyles = makeStyles(() => ({
  title: {
    color: "#FF3030",
    marginRight: 6,
    fontSize: 14,
  },
  info: {
    marginLeft: 6,
    marginRight: 6,
    fontSize: 14,
  },
  commit: {
    color: "#0045FF",
    fontSize: 14,
  },
}));

export default function AppVersion({
  clientInfo,
  serverInfo,
}: {
  clientInfo: any;
  serverInfo: any;
}) {
  return null;
  /*     return (
        <Grid
            container spacing={3}
            style={{position: "absolute", botttom: 0}}
            direction="column"
        >
            <Feedback env={[clientInfo,serverInfo]}/>
            <Grid
                container
                justifyContent="flex-end"
                spacing={3}
                direction="column"
                style={{paddingTop: 30}}
                item
            >
                <Grid item  md={4} style={{paddingTop: 0}}>
                    {clientInfo ? <VersionType commitInfo={clientInfo} appName={"Client"}/> : null}
                </Grid>
                <Grid item md={4} style={{paddingTop: 0}}>
                    {serverInfo ? <VersionType commitInfo={serverInfo} appName={"Server"}/> : null}
                </Grid>
            </Grid>
        </Grid>
    ) */
}

function VersionType({
  commitInfo,
  appName,
}: {
  commitInfo: any;
  appName: any;
}) {
  const { branch, date, shortHash, tag } = commitInfo;
  const classes = useStyles();
  return null;
  /*     return(
        <Grid item md={12} container justifyContent="flex-end">
            <Typography className={classes.title}>{appName}:</Typography>
            <Typography className={classes.commit}>{tag.startsWith(shortHash) ? branch : shortHash}</Typography>
            <Typography className={classes.commit}>{`( ${shortHash} )`}</Typography>
            <Typography className={classes.info}>{new Date(date).toDateString()}</Typography>
        </Grid>
    ) */
}
