import { useState, useEffect, useCallback } from "react";

import { FhirPlanDefinitionMod } from "models/fhirPlanDefinition";
import { FhirOrganizationMod } from "models/organization";
import { FhirPatientMod } from "models/patient";
import { FhirPractitionerMod } from "models/practitioner";
import { FhirServiceRequestMod } from "models/serviceRequest";
import { FhirTaskMod } from "models/task";
import { FhirQuestionnaireMod } from "models/questionnaire";
import { FhirQuestionnaireResponseMod } from "models/questionnaireResponse";
import { FhirRelatedPersonMod } from "models/relatedPerson";
import { FhirMeasureReportMod } from "models/measureReport";
import {
  getResourceRef,
  getSplitReference,
  getTaskNumberFromIdentifiers,
  getUuidsFromInOutPut,
} from "utils/fhirUtils";
import agent from "../api/agent";
import { indexedInt } from "models/fhirQuery";
import { urlBackNestApp } from "routes/urls";

// ----------------------------------------------------------------------

interface TaskProps {
  // fhirServiceRequest: FhirServiceRequestMod.ServiceRequest;
  // fhirPractitioner?: FhirPractitionerMod.Practitioner;
  // fhirPatient?: FhirPatientMod.Patient;
  // fhirOrganization?: FhirOrganizationMod.Organization;
  // fhirGeneralPractitioner?: FhirPractitionerMod.Practitioner;
  fhirTask?: FhirTaskMod.Task;
  // fhirPlanDefinition?: FhirPlanDefinitionMod.PlanDefinition;
  // isNewPatient?: boolean;
  // devMode: boolean;
  // refreshFhirServiceRequest?: any;
  // showQRList?: boolean;
  // showMeasureQR: boolean;
}

export default function useTask() {
  const [userInfo, setUserInfo] = useState("");
  const [fhirTask, setFhirTask] = useState(null);

  const [indexedfhirQuestionnaires, setIndexedFhirQuestionnaires] = useState<
    indexedInt<FhirQuestionnaireMod.Questionnaire>
  >();
  const [
    indexedfhirQuestionnaireResponses,
    setIndexedFhirQuestionnaireResponses,
  ] = useState<
    indexedInt<FhirQuestionnaireResponseMod.QuestionnaireResponse>
  >();
  const [
    fhirMeasureReport,
    setFhirMeasureReport,
  ] = useState<FhirMeasureReportMod.MeasureReport>();
  const [fhirObservation, setFhirObservation] = useState<any>(null);
  const [
    relatedPersonTaskOwner,
    setRelatedPersonTaskOwner,
  ] = useState<FhirRelatedPersonMod.RelatedPerson>();
  const [
    patientTaskOwner,
    setPatientTaskOwner,
  ] = useState<FhirPatientMod.Patient>();
  const [indexedAnswerMaps, setIndexedAnswerMaps] = useState<
    indexedInt<FhirQuestionnaireResponseMod.QuestionnaireAnswerMap[]>
  >();

  const _setFhirMeasureReport = async (uuid: string) => {
    const response = await agent.FhirMeasureReport.details(uuid);
    setFhirMeasureReport(response);
  };

  const _setFhirObservation = async (uuid: string) => {
    const response = await agent.FhirObservation.bySubject(uuid);
    setFhirObservation(response);
  };

  const _setFhirQuestionnaires = async (uuids: string[]) => {
    const response = await agent.FhirQuestionnaire.indexedList(uuids);
    setIndexedFhirQuestionnaires(response);
    _setIndexedAnswerMap(response);
  };

  const _setIndexedAnswerMap = async (
    indexedfhirQuestionnaires: indexedInt<FhirQuestionnaireMod.Questionnaire>
  ) => {
    if (indexedfhirQuestionnaires) {
      // console.log("xxxcurrentIndexed", indexedfhirQuestionnaires)
      const data = Object.keys(indexedfhirQuestionnaires);
      // console.log("xxxdata", data)
      let newValue: indexedInt<
        FhirQuestionnaireResponseMod.QuestionnaireAnswerMap[]
      > = {};
      for (let i = 0; i < data.length; i++) {
        const response = await fetch(
          `${urlBackNestApp}/questionaire/answermap/${data[i]}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("encryptUser")}`,
            },
          }
        );
        // console.log("setIndexedAnswerMaps", response)
        const responseJson = await response.json();
        // console.log("responseJson", responseJson)
        newValue = {
          ...newValue,
          [data[i]]: responseJson,
        };
      }
      setIndexedAnswerMaps(newValue);
    }
  };

  const _setFhirQuestionnaireResponses = async (uuids: string[]) => {
    const OOC = "OOC";
    const response = await agent.FhirQuestionnaireResponse.indexedList(uuids);

    let filteringResponseBySecurityTag = {};
    Object.entries(response).forEach(([key, value]) => {
      const newObject = {
        [key]: {
          ...value,
          ...(value?.meta?.security?.[0]?.code === OOC
            ? {
                item: [
                  {
                    answer: [
                      {
                        valueCoding: {
                          code: OOC,
                          display: "Report my scores only",
                        },
                      },
                    ],
                    linkId: "/pearls_youth_parent_caregiver/consent",
                    text:
                      "The answers you provide below are considered sensitive information. Please tell us how you want this information to be used and shared. Choose one:",
                  },
                ],
                isSensitive: false,
              }
            : { item: value?.item, isSensitive: false }),
        },
      };
      Object.assign(filteringResponseBySecurityTag, newObject);
    });

    const getTotalResponseItems = Object.entries(response).reduce(
      (acc: any, obj: any) => {
        return acc + obj?.[1]?.item?.length;
      },
      0
    );

    const getTotalFilteringItems = Object.entries(
      filteringResponseBySecurityTag
    ).reduce((acc: any, obj: any) => {
      return acc + obj?.[1]?.item?.length;
    }, 0);
    setIndexedFhirQuestionnaireResponses(filteringResponseBySecurityTag);
  };

  const _setTaskOwner = async (ownerResourceType: string, ownerId: string) => {
    console.log("xxownerId", ownerId);
    if (ownerResourceType === "Patient") {
      const response = await agent.FhirPatient.details(ownerId);
      console.log("xxresponse", response);
      setPatientTaskOwner(response);
    } else if (ownerResourceType === "RelatedPerson") {
      const response = await agent.FhirRelatedPerson.details(ownerId);
      setRelatedPersonTaskOwner(response);
    }
  };

  useEffect(() => {
    fetchUser();

    if (fhirTask && fhirTask.id) {
      if (fhirTask.owner?.reference) {
        const ownerSplitRef = getSplitReference(fhirTask.owner.reference);
        console.log("xxownerSplitRef", ownerSplitRef);
        _setFhirObservation(ownerSplitRef.uuid);
        _setTaskOwner(ownerSplitRef.resourceType, ownerSplitRef.uuid);
      }

      if (fhirTask.input) {
        const questionnaireUuids = getUuidsFromInOutPut(
          fhirTask.input,
          "Questionnaire"
        );

        if (Array.isArray(questionnaireUuids))
          _setFhirQuestionnaires(questionnaireUuids);
      }

      if (fhirTask.output) {
        const questionnaireResponseUuids = getUuidsFromInOutPut(
          fhirTask.output,
          "QuestionnaireResponse"
        );

        if (Array.isArray(questionnaireResponseUuids)) {
          _setFhirQuestionnaireResponses(questionnaireResponseUuids);
        }

        const measureReportUuids = getUuidsFromInOutPut(
          fhirTask.output,
          "MeasureReport"
        );

        if (measureReportUuids && measureReportUuids.length > 0)
          _setFhirMeasureReport(measureReportUuids[0]);
      }
    }
  }, [fhirTask]);

  const fetchUser = useCallback(() => {
    fetch(`${urlBackNestApp}/users/${fhirTask?.identifier?.[0]?.value}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("encryptUser")}`,
      },
    })
      .then((userInfo) => userInfo.json())
      .then((userInfo) => {
        setUserInfo(userInfo || {});
      });
  }, [fhirTask]);

  return {
    fhirTask,
    setFhirTask,
    userInfo,
    indexedfhirQuestionnaires,
    setIndexedFhirQuestionnaires,
    indexedfhirQuestionnaireResponses,
    setIndexedFhirQuestionnaireResponses,
    fhirMeasureReport,
    setFhirMeasureReport,
    relatedPersonTaskOwner,
    setRelatedPersonTaskOwner,
    patientTaskOwner,
    setPatientTaskOwner,
    indexedAnswerMaps,
    setIndexedAnswerMaps,
    fhirObservation,
    setFhirObservation,
  };
}
