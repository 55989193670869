import { useState, useEffect } from 'react';
import { Button, Typography, Grid} from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Cookies from 'js-cookie';
import useAuth from 'hooks/useAuth';
import {urlBackNestApp} from '../../../routes/urls'
import { PATH_DASHBOARD } from '../../../routes/paths';
import { useNavigate } from 'react-router-dom';

function getModalStyle() {
    return {
      top: `50%`,
      left: `50%`,
      transform: `translate(-50%, -50%)`,
    };
  }
  
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '0.3px solid #000',
        boxShadow: '0px 0px 14px 5px rgba(0,0,0,0.63)',
        padding: theme.spacing(2, 4, 3),
      },
    }),
  );

export function InactiveUserModal({msg}:{msg: string}){
    const classes = useStyles();
    const [modalStyle] = useState(getModalStyle);
    const {logout} = useAuth();

    const handleInactiveUser = () => {
      Cookies.remove('access_token');
      Cookies.remove('encrypt_user');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('encryptUser');
      
      const urlredirect: string = process.env.REACT_APP_KEYCLOAK_URL as string || '';
      window.location.href = urlredirect
    }

    return  (
      <div style={modalStyle} className={classes.paper}>
        <Grid container flexDirection="column">
          <Grid item >
            <Typography variant="h3" component="h1" paragraph>
              Not Access
            </Typography>
          </Grid>
          <Grid item >
            <Typography variant="body1" component="p" paragraph>
              {msg}
            </Typography>
          </Grid>
          <Grid item container justifyContent="flex-end">
            <Button onClick={logout}>
              Return to Login
            </Button>
          </Grid>
        </Grid>
      </div>
    );
}

export function ExpireSessionModal({data}:{data: any}){
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const {logout} = useAuth();
  const navigate = useNavigate();
  const [seconds, setSeconds] = useState(60);

  const goToApp = async () => {
    try{
      const resp = await fetch(`${urlBackNestApp}/keycloak/refresh-token`, {
        method: 'post',
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("encryptUser")}`,
        },
        body: JSON.stringify(data),
      })
      const json = await resp.json()
      if(json.msg) navigate(PATH_DASHBOARD.root, { replace: true });
    } catch(e) {
      console.log("Refresh fail", e)
    }
  }

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      logout()
    }
  });

  return  (
    <div style={modalStyle} className={classes.paper}>
      <Grid container flexDirection="column">
        <Grid item >
          <Typography variant="h3" component="h1" paragraph>
            Session Expired
          </Typography>
        </Grid>
        <Grid item >
          <Typography variant="body1" component="p" paragraph>
            You will be redirect to login in : <b>{seconds}</b> seconds
          </Typography>
        </Grid>
        <Grid item container justifyContent="flex-end">
          <Button onClick={logout}>
            Logout
          </Button>
        </Grid>
        <Grid item container justifyContent="flex-end">
          <Button onClick={goToApp}>
            Return to Aplication
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
