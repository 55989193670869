import { useTranslation } from "react-i18next";
import "translation/i18n";
import moment from 'moment';
import { Typography } from "@material-ui/core";

const DispositionComponent = (props: any) => {
  const { t } = useTranslation();
  const {
    patient,
  } = props?.others;

  return (
    <div>
      {patient?.dispositionBy && (
        <Typography variant="subtitle2" gutterBottom component="div">
          <strong>Disposition By:</strong> <span>{patient.dispositionBy}</span>
        </Typography>
      )}

      {patient?.dispositionOn && (
        <Typography variant="subtitle2" gutterBottom component="div">
          <strong>Disposition On:</strong> <span>{moment(new Date(patient.dispositionOn)).format('MM-DD-YYYY HH:mm')}</span>
        </Typography>
      )}

      {patient?.dispositionReason && (
        <Typography variant="subtitle2" gutterBottom component="div">
          <strong>Disposition Reason:</strong>{" "}
          <span>{patient.dispositionReason}</span>
        </Typography>
      )}

      {patient?.dispositionNote && (
        <Typography variant="subtitle2" gutterBottom component="div">
          <strong>Disposition Note:</strong>{" "}
          <span>{patient.dispositionNote}</span>
        </Typography>
      )}
    </div>
  );
};

export default DispositionComponent;
