import { useRef } from 'react';
import { capitalCase } from 'change-case';
import {Link as RouterLink, useLocation} from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Card, Stack, Link, Alert, Tooltip, Container, Typography, CircularProgress, Grid, Modal } from '@material-ui/core';
// routes
import { PATH_AUTH } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';
// layouts
import AuthLayout from '../../layouts/AuthLayout';
// components
import Page from '../../components/Page';
import { MHidden } from '../../components/@material-extend';
import { LoginForm } from '../../components/authentication/login';
import LoadingScreen from "../../components/LoadingScreen";
import { InactiveUserModal } from 'components/authentication/verify-user';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { method } = useAuth();
  const { pathname } = useLocation();
  const isDashboard = pathname.includes("/dashboard");
  const rootRef = useRef<HTMLDivElement>(null);
  const accessToken = localStorage.getItem('accessToken')

  if(accessToken){
    return decodeURI(accessToken) === 'Not Signed' ? (
      <Grid style={{height: '100vh'}} container justifyContent="center" alignContent="center">
        <Modal
          disablePortal
          disableEscapeKeyDown
          disableScrollLock
          disableEnforceFocus
          disableAutoFocus
          open
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          container={() => rootRef.current}
        >
          <InactiveUserModal msg={'Your are not user from the Portal, please contact you provider'}/>
        </Modal>
        {/*<AuthLayout>*/}
        {/*  Don’t have an account? &nbsp;*/}
        {/*  <Link underline="none" variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>*/}
        {/*    Get started*/}
        {/*  </Link>*/}
        {/*</AuthLayout>*/}
  
        {/*<MHidden width="mdDown">*/}
        {/*  <SectionStyle>*/}
        {/*    <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>*/}
        {/*      Hi, Welcome Back*/}
        {/*    </Typography>*/}
        {/*    <img src="/static/illustrations/illustration_login.png" alt="login" />*/}
        {/*  </SectionStyle>*/}
        {/*</MHidden>*/}
  
        {/*<Container maxWidth="sm">*/}
        {/*  <ContentStyle>*/}
        {/*    <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>*/}
        {/*      <Box sx={{ flexGrow: 1 }}>*/}
        {/*        <Typography variant="h4" gutterBottom>*/}
        {/*          Sign in to Minimal*/}
        {/*        </Typography>*/}
        {/*        <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>*/}
        {/*      </Box>*/}
  
        {/*      <Tooltip title={capitalCase(method)}>*/}
        {/*        <Box*/}
        {/*          component="img"*/}
        {/*          src={`/static/auth/ic_${method}.png`}*/}
        {/*          sx={{ width: 32, height: 32 }}*/}
        {/*        />*/}
        {/*      </Tooltip>*/}
        {/*    </Stack>*/}
  
        {/*    <Alert severity="info" sx={{ mb: 3 }}>*/}
        {/*      Use email : <strong>demo@minimals.cc</strong> / password :*/}
        {/*      <strong>&nbsp;demo1234</strong>*/}
        {/*    </Alert>*/}
  
        {/*    <LoginForm />*/}
  
        {/*    <MHidden width="smUp">*/}
        {/*      <Typography variant="body2" align="center" sx={{ mt: 3 }}>*/}
        {/*        Don’t have an account?&nbsp;*/}
        {/*        <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>*/}
        {/*          Get started*/}
        {/*        </Link>*/}
        {/*      </Typography>*/}
        {/*    </MHidden>*/}
        {/*  </ContentStyle>*/}
        {/*</Container>*/}
      </Grid>
    ) : (
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: "fixed",
            }),
          }}
        />
    )
  }
  return <LoadingScreen
    sx={{
      ...(!isDashboard && {
        top: 0,
        left: 0,
        width: 1,
        zIndex: 9999,
        position: "fixed",
      }),
    }}
  />
}
