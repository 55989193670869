import { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  DialogTitle,
} from "@material-ui/core";

const ConfirmDialog = ({ isOpen, handlerConfirmation, handlerIsOpenConfirmDialog }: any) => {
  const [openDialog, setOpenDialog] = useState(isOpen);

  useEffect(() => {
    setOpenDialog(isOpen);
  }, [isOpen]);

  const handleCloseForm = () => {
    setOpenDialog(false);
    handlerIsOpenConfirmDialog(false);
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleCloseForm}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="confirm-dialog-title">PLEASE CONFIRM THE CLOSE ACTION</DialogTitle>
      <DialogContent>
        Are you sure you want to close the workflow?
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseForm} variant="contained" color="error">
          Disagree
        </Button>
        <Button onClick={() => handlerConfirmation()} variant="contained" color="success" autoFocus>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
