import {
  Alert,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Snackbar,
  Stack,
  Typography,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import KeyValueGrid from "components/KeyValueGrid";
import KeyValuePaper from "components/KeyValuePaper";
import Page from "components/Page";
import ConfirmContact from "components/patient-engagement/ConfirmContact";
import ConfirmGuarantorInfo from "components/patient-engagement/ConfirmGuarantorInfo";
import ConfirmMemberInfo from "components/patient-engagement/ConfirmMemberInfo";
import ConfirmNatOptIn from "components/patient-engagement/ConfirmNatOptIn";
import ScheduleNatSession from "components/patient-engagement/ScheduleNatSession";
import usePostMessage from "hooks/usePostMessage";
import { useContext, useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { urlBackNestApp } from "routes/urls";
import { dateYearFormatWithAge } from "utils/datesFormats";
import agent from "../../api/agent";
import ConfirmCMOptInItem from "../../components/patient-engagement/ConfirmCMOptInItem";
import TasksList from "../../components/patient-engagement/ListTasks";
import ReviewNatReportItem from "../../components/patient-engagement/ReviewNatReportItem";
import { ComxInteractiveScreenContext } from "../../contexts/comxInteractiveScreenContext";
import CreateOrder, {
  IPatientCreateOrder,
} from "../../pages/orders/CreateOrder";
import { getResourceRef, getSplitReference } from "../../utils/fhirUtils";
import DispositionType from "../workflow/common/components/Disposition";
import Input from "../workflow/common/components/Input";
import SelectType from "../workflow/common/components/Select";
import Workflow from "../workflow/Workflow";
import ComplementaryInfoLibrary from "./ComplementaryInfo";
import NotesList from "components/patient-engagement/NotesList";
import * as moment from "moment";
import { useTranslation } from "react-i18next";
import "../../translation/i18n";
import { formatPatient } from "utils/patientFormat";
import { getBaseUrlNative } from "_helpers";
import { HealthcarePatient } from "models/patient";
import { getContactDataFromPatientForComx } from "utils/patientFormat";
import { withStyles } from "@material-ui/core/styles";
import { Clear as ClearIcon, Edit as EditIcon } from "@material-ui/icons";
import DispositionDialog from "pages/workflow/common/Dialog/DispositionDialog";
import { isArray, isEmpty, isObject } from "lodash";

export default function PatientEngagementDetails() {
  const { isOpen, setIsOpen } = useContext(ComxInteractiveScreenContext);
  const [type, setType] = useState("");
  const [patientEngagement, setPatientEngagement] = useState<any>({});
  const [contactAttempts, setContactAttempts] = useState<any>([]);
  const [disposition, setDisposition] = useState<any>(false);
  const [noReasonForDisposition, setNoReasonForDisposition] = useState<any>(
    false
  );
  const [confirmation, setConfirmation] = useState<any>(false);
  const [createRequest, setCreateRequest] = useState<any>(false);
  const [confirmOptInItem, setConfirmOptInItem] = useState<any>(false);
  const [reviewNatReportItem, setReviewNatReportItem] = useState<any>(false);
  const [showTasksList, setShowTasksList] = useState<boolean>(false);
  const [showNotesList, setShowNotesList] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [optInStatus, setOptInStatus] = useState("");
  const [patientId, setPatientId] = useState("");
  const [patient, setPatient] = useState<any>();
  const [isEnabledEngageButton, setIsEnabledEngageButton] = useState(false);
  const [taskData, setTaskData] = useState<any>();
  const [assesmentStatus, setAssesmentStatus] = useState("");
  const [patientSIL, setPatientSIL] = useState<any>();

  const comxEnabled =
  (process.env?.REACT_APP_ENABLE_COMX_INTERACTIVE &&
    process.env?.REACT_APP_ENABLE_COMX_INTERACTIVE === "true") ||
  false;

  const getOptInStatus = async () => {
    const response = await agent.FhirPatientConsent.byPatient(patientId);
    const optInValidation =
      response?.["entry"]?.[0]?.["resource"]?.["policyRule"]?.["coding"]?.[0]?.[
        "code"
      ];
    const optInPath =
      response?.["entry"]?.[0]?.["resource"]?.["policyRule"]?.["coding"]?.[0]?.[
        "code"
      ];
    if (optInValidation) {
      if (optInPath === "OIC" || optInPath === "OIS") {
        setOptInStatus("Opt In");
      }
      if (optInPath === "OOC" || optInPath === "OOS") {
        setOptInStatus("Opt Out");
      }
    } else {
      setOptInStatus("Not available");
    }
  };

  useEffect(() => {
    if (patientEngagement) {
      const fhirUri = patientEngagement.fhir_uri;
      if (fhirUri) {
        const splittingFhirUri = fhirUri.split("/");
        const patientId = splittingFhirUri[1];
        setPatientId(patientId);
      }
    }
  }, [patientEngagement]);

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
      marginTop: "-145px",
    },
  }))(Tooltip);

  useEffect(() => {
    if (patientId) {
      fetchPatient();
      fetchContactAttempts();
      getOptInStatus();
    }
  }, [patientId]);

  useEffect(() => {
    if (patient) {
      setIsEnabledEngageButton(true);
    }
  }, [patient]);

  const { patientEngagementId } = useParams();
  const [
    currentPatientCreateOrder,
    setCurrentPatientCreateOrder,
  ] = useState<IPatientCreateOrder>();
  const [checklistData, setChecklistData] = useState<any>([]);
  const [
    checklistRefreshHandler,
    setChecklistRefreshHandler,
  ] = useState<Function>();
  const [recordRefreshHandler, setRecordRefreshHandler] = useState<Function>();

  const { t } = useTranslation();

  const { sendToChild } = usePostMessage("comx", () => {
    // console.log("usePostMessage from NAT");
  });

  const engage = (data: any) => {
    if (data && data?.fhir_uri && patient && isEnabledEngageButton) {
      const resRef = getResourceRef(data?.fhir_uri);
      if (resRef !== undefined) {
        const splitRef = getSplitReference(resRef);
        if (splitRef.resourceType === "Patient" && splitRef.uuid) {
          const payload: any = {
            integrationId: splitRef.uuid,
            firstName: data?.given_name,
            lastName: data?.family_name,
            postMessageType: "SELECT_CONTACT",
          };

          const contactData = getContactDataFromPatientForComx(patient);

          if (contactData?.phone) {
            payload.phone = contactData?.phone;
          }
          if (contactData?.email) {
            payload.email = contactData?.email;
          }
          if (contactData?.phone || contactData?.email) {
            setIsOpen(true);
            sendToChild({ payload });
          }
        }
      }
    }
  };
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState<any>(false);
  const [successMessageSnackbar, setSuccessMessageSnackbar] = useState<any>();
  const [confirmGuarantorModalOpen, setConfirmGuarantorModalOpen] = useState(
    false
  );
  const [confirmNatOptInModalOpen, setConfirmNatOptInModalOpen] = useState(
    false
  );
  const [
    scheduleNatSessionModalOpen,
    setScheduleNatSessionModalOpen,
  ] = useState(false);
  const [confirmContactModalOpen, setConfirmContactModalOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [
    isEnabledComplementaryInfoButton,
    setIsEnabledComplementaryInfoButton,
  ] = useState(true);
  const [complementaryStore, setComplementaryStore] = useState({});
  const [isOpenReAssign, setIsOpenReAssign] = useState(false);

  const isDate = (label: string, value: string) => {
    const tmpArray = ["validatedOn", "dateHour"];
    const d = Date.parse(value);

    return !isNaN(d) && tmpArray.includes(label) ? true : false;
  };

  const handleCloseSnackbar = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const assignUser = async (
    user: any,
    refreshChecklistHandler: any,
    refreshRecordHandler: any
  ) => {
    const payload = {
      ...patientEngagement,
      owned_by: user,
    };

    try {
      await agent.PatientEngagement.updatePatientById(
        patientEngagement?.id,
        payload
      );
      setOpenSnackbar(true);
      setSuccessMessageSnackbar("Assigned user successfully!");
      refreshChecklistHandler();
    } catch (err) {
      console.log("error on trying to update patient engagement record");
    } finally {
      setIsOpenReAssign(false);
      if (refreshRecordHandler) refreshRecordHandler();
    }
  };

  //Assesment Status

  const getTaskData = async () => {
    if (patientId) {
      try {
        const response = await agent.Order.getTask(patientId);
        setTaskData(response);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getTaskData();
  }, [patientId]);

  useEffect(() => {
    if (patientId) {
      getObservationSILByPatiens(patientId);
    }
  }, [patientId]);

  useEffect(() => {
    if (taskData?.task?.status) {
      setAssesmentStatus(taskData?.task?.status);
    }
  }, [taskData]);

  const getPatientEngagementDetails = async () => {
    try {
      const resp = await fetch(
        `${urlBackNestApp}/patientengagement/${patientEngagementId}`,
        {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("encryptUser")}`,
          },
        }
      );
      const json = await resp.json();
      console.log("DEBUG json: ", json);
      setPatientEngagement(json);
    } catch (e) {
      console.log(`Something failed get patient engagement details ${e}`);
    }
  };

  useEffect(() => {
    fetchAllUsers();
  }, []);
  //

  // const fetchPatient = async () => {
  //   const fetchurl: string =
  //     (getBaseUrlNative(
  //       process.env.REACT_APP_ORGANIZATIONS_ENDPOINT,
  //       `/healthcare/patient/${patientId}`
  //     ) as string) || "";
  //   fetch(`${fetchurl}`)
  //     .then((data) => data.json())
  //     .then((data) => {
  //       setPatient(formatPatient(data));
  //     });
  // };

  const fetchPatient = async () => {
    if (patientId) {
      try {
        const rawPatient: HealthcarePatient = await agent.Patient.get(
          patientId
        );
        setPatient(rawPatient);
      } catch (err) {
        console.log(
          "patientEngagement Details: error on fetching patient",
          err
        );
      }
    }
  };

  const fetchAllUsers = async () => {
    try {
      const getAllUsers = await agent.User.allUser();
      setAllUsers(
        getAllUsers[0]
          .sort((_a: any, _b: any) => {
            const a = (_a.firstName + " " + _a.lastName).toLowerCase(),
              b = (_b.firstName + " " + _b.lastName).toLowerCase();

            if (a < b) return -1;

            if (a > b) return 1;

            return 0;
          })
          .filter((e: any) => e.status === "Active")
      );
    } catch (err) {
      console.log("error on fetching all users", err);
    }
  };

  const fetchContactAttempts = useCallback(async () => {
    const response: any = await agent.PatientEngagement.getPatientContactAttempt(
      patientEngagement?.id
    );
    const contactAttempts = response.sort(
      (a: any, b: any) => new Date(a?.createdOn) - new Date(b?.createdOn)
    );
    setContactAttempts(contactAttempts);
  }, [patientEngagement?.id]);

  // const fetchContactAttempts = async () => {
  //   try {
  //     const response = await agent.PatientEngagement.getPatientContactAttempt(
  //       patientEngagement?.id
  //     );
  //     setContactAttempts(response);
  //   } catch (err) {
  //     console.log("error on fetching contact attempt", err);
  //   }
  // };

  const { data = {}, dataSchema = {} } = patientEngagement;
  const parsePatientEngagementData =
    data && typeof data === "string" ? JSON.parse(data) : data;

  const getComplementaryInfoName = (key: any) => {
    let complementaryKeyName = "";

    switch (key) {
      case "CONFIRM_MEMBER_INFO_ITEM":
        complementaryKeyName = "Confirm Member";
        break;
      case "CONFIRM_GUARANTOR_INFO_ITEM":
        complementaryKeyName = "Confirm Parent/Caregiver";
        break;
      case "CONFIRM_NAT_OPTIN_ITEM":
        complementaryKeyName = "Confirm HJ OptIn";
        break;
      case "SCHEDULE_NAT_SESSION_ITEM":
        complementaryKeyName = "Schedule HJ Session";
        break;
      case "REVIEW_NAT_REPORT_ITEM":
        complementaryKeyName = "Review HJ Report";
        break;
      case "CONFIRM_CM_OPTIN_ITEM":
        complementaryKeyName = "Confirm CM OptIn";
        break;
      case "INITIATE_NAT_REQUEST_ITEM":
        complementaryKeyName = "Initiate HJ Request";
        break;
      case "validatedOn":
        complementaryKeyName = "Validated On";
        break;
      case "validatedBy":
        complementaryKeyName = "Validated By";
        break;
      case "notes":
        complementaryKeyName = "Notes";
        break;
      case "opt_in_decision":
        complementaryKeyName = "Opt In Decision";
        break;
      case "type":
        complementaryKeyName = "Type";
        break;
      case "method":
        complementaryKeyName = "Method";
        break;
      case "dateHour":
        complementaryKeyName = "Date Hour";
        break;
      case "patientId":
        complementaryKeyName = "Patient ID";
        break;
      case "patientContact":
        complementaryKeyName = "Patient Contact";
        break;
      case "phone":
        complementaryKeyName = "Patient Phone";
        break;
      case "email":
        complementaryKeyName = "Patient Email";
        break;
      case "firstName":
        complementaryKeyName = "Patient First Name";
        break;
      case "lastName":
        complementaryKeyName = "Patient Last Name";
        break;
      case "orderid":
        complementaryKeyName = "Order ID";
        break;
      case "existingorder":
        complementaryKeyName = "Existing Order";
        break;
      default:
        complementaryKeyName = "Not Found Key";
    }

    return complementaryKeyName;
  };

  const WorkflowComponentMapping: any = {
    Input,
    Select: SelectType,
    Disposition: DispositionType,
  };

  const handlerConfirmation = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setConfirmation(true);
  };

  const handlerDisposition = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setDisposition(true);
    getPatientEngagementDetails();
  };

  const handlerDispositionWithoutReason = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setNoReasonForDisposition(true);
    setDisposition(true);
    getPatientEngagementDetails();
  };

  const handlerAssignUser = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setChecklistData(checklistItem);
    // setChecklistRefreshHandler(() => refreshChecklistHandler);
    assignUser(
      more?.selectedUser,
      refreshChecklistHandler,
      refreshRecordHandler
    );
  };

  const handlerEngage = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setChecklistData(checklistItem);
    setChecklistRefreshHandler(() => refreshChecklistHandler);
    setConfirmContactModalOpen(true);
  };

  const handlerConfirmMemberInfo = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    engage(record);
    setChecklistData(checklistItem);
    setChecklistRefreshHandler(() => refreshChecklistHandler);
    setRecordRefreshHandler(() => getPatientEngagementDetails);
    setConfirmModalOpen(true);
  };

  const handlerConfirmationGuarantorInfo = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setChecklistData(checklistItem);
    setChecklistRefreshHandler(() => refreshChecklistHandler);
    setConfirmGuarantorModalOpen(true);
  };

  const handlerConfirmationNatOptIn = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setChecklistData(checklistItem);
    setChecklistRefreshHandler(() => refreshChecklistHandler);
    setRecordRefreshHandler(() => getPatientEngagementDetails);
    setConfirmNatOptInModalOpen(true);
  };

  const handlerConfirmationScheduleNatSession = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setChecklistData(checklistItem);
    setChecklistRefreshHandler(() => refreshChecklistHandler);
    setScheduleNatSessionModalOpen(true);
  };

  const handlerConfirmationReviewNat = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setChecklistData(checklistItem);
    setChecklistRefreshHandler(() => refreshChecklistHandler);
    setReviewNatReportItem(true);
  };

  const handlerConfirmationCmOptIn = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setChecklistData(checklistItem);
    setChecklistRefreshHandler(() => refreshChecklistHandler);
    setConfirmOptInItem(true);
  };

  const handlerConfirmationInitiateNatRequest = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setChecklistData(checklistItem);
    setChecklistRefreshHandler(() => refreshChecklistHandler);
    setCreateRequest(true);
  };

  const handlerConfirmCompleteNatInterview = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setChecklistData(checklistItem);
    setChecklistRefreshHandler(() => refreshChecklistHandler);
    setShowTasksList(true);
  };

  const addPatientEngagementChecklistItem = async (
    code: string,
    message: string,
    values: any,
    convertValuesToJson = true,
    openDispositionWhenFinished = false
  ) => {
    const payload = {
      checklistItemId: checklistData.id,
      workflowStageChecklistRef: null,
      patientEngagementRef: patientEngagement.id,
      code: code,
      message: message,
      meta:
        typeof values !== "string" && convertValuesToJson
          ? JSON.stringify(values)
          : values,
    };

    try {
      await agent.PatientEngagement.checkitem(payload);
      if (checklistRefreshHandler) checklistRefreshHandler();
      if(recordRefreshHandler) setTimeout(() => recordRefreshHandler(), 1500);
      if (openDispositionWhenFinished) {
        WorkflowHandler["setDisposition"]["handler"](false);
      }
    } catch (err) {
      console.log("error on adding patient engagement checklist item", err);
    }
  };

  const WorkflowHandler: any = {
    setConfirmation: {
      type: "confirm",
      handler: handlerConfirmation,
      data: true,
    },
    setDisposition: {
      type: "confirm",
      handler: handlerDisposition,
      data: true,
    },
    setDispositionWithoutReason: {
      type: "confirm",
      handler: handlerDispositionWithoutReason,
      data: true,
    },
    ASSIGN_OWNER_ITEM: {
      type: "payload",
      handler: handlerAssignUser,
      data: allUsers,
    },
    CONTACT_ITEM: {
      type: "payload",
      handler: handlerEngage,
      data: patientEngagement,
    },
    CONFIRM_MEMBER_INFO_ITEM: {
      type: "confirm",
      handler: handlerConfirmMemberInfo,
      data: true,
    },
    CONFIRM_GUARANTOR_INFO_ITEM: {
      type: "confirm",
      handler: handlerConfirmationGuarantorInfo,
      data: true,
    },
    CONFIRM_NAT_OPTIN_ITEM: {
      type: "confirm",
      handler: handlerConfirmationNatOptIn,
      data: true,
    },
    SCHEDULE_NAT_SESSION_ITEM: {
      type: "confirm",
      handler: handlerConfirmationScheduleNatSession,
      data: true,
    },
    REVIEW_NAT_REPORT_ITEM: {
      type: "confirm",
      handler: handlerConfirmationReviewNat,
      data: true,
    },
    CONFIRM_CM_OPTIN_ITEM: {
      type: "confirm",
      handler: handlerConfirmationCmOptIn,
      data: true,
    },
    INITIATE_NAT_REQUEST_ITEM: {
      type: "confirm",
      handler: handlerConfirmationInitiateNatRequest,
      data: true,
    },
    COMPLETE_NAT_INTERVIEW_ITEM: {
      type: "confirm",
      handler: handlerConfirmCompleteNatInterview,
      data: true,
    },
    DISPOSITION_ITEM: {
      type: "confirm",
      handler: () => {},
      data: true,
    },
  };

  const WorkflowData: any = {
    confirmation,
    disposition,
    noReasonForDisposition,
  };

  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      Dashboard
    </Typography>,
    <Typography key="3">Member Engagement List</Typography>,
    <Typography key="3">{patientEngagement.uuid}</Typography>,
  ];

  const getObservationSILByPatiens = async (patientFhirId: string) => {
    const data = await agent.FhirObservation.getByPatientCode(
      patientFhirId,
      "sil"
    );
    let sil: any;
    if (data.total > 0) {
      const patient_observations =
        data?.entry?.[0].resource?.valueCodeableConcept &&
        data?.entry?.[0]?.resource?.valueCodeableConcept?.coding?.[0].display;
      sil = patient_observations;
    }
    setPatientSIL(sil);
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const getType = query.get("type");
    setType(`${getType}`);
    getPatientEngagementDetails();

    return () => {
      setPatientEngagement({});
    };
  }, []);

  useEffect(() => {
    if (
      patientEngagement === undefined ||
      patientEngagement?.fhir_uri === undefined
    )
      return;

    const resRef = getResourceRef(patientEngagement?.fhir_uri);

    if (resRef === undefined) return;
    const splitRef = getSplitReference(resRef);

    const patientCreateOrder: IPatientCreateOrder = {
      uuid: splitRef.uuid,
      name:
        patientEngagement?.given_name + " " + patientEngagement?.family_name,
      birthDate: patientEngagement?.dob,
      identifier: [],
    };

    setCurrentPatientCreateOrder(patientCreateOrder);
  }, [patientEngagement]);

  const ContactAttemptsComp = (records: any) => {
    return contactAttempts &&
      contactAttempts instanceof Array &&
      contactAttempts.length > 0 ? (
      contactAttempts.map((item: any, index: any) => {
        return (
          <div
            style={{ marginBottom: 5, maxWidth: "100%", overflow: "hidden" }}
            id={item["id"]}
          >
            <Typography variant="h5" gutterBottom component="div">
              Attempt {index + 1}
            </Typography>

            <KeyValueGrid
              label="When"
              value={moment(new Date(item?.contactOn)).format(
                "MM-DD-YYYY HH:mm"
              )}
            />
            <KeyValueGrid label="Outcome" value={item?.outcome} />
            <KeyValueGrid
              label="Next Contact On"
              value={moment(new Date(item?.nextContactOn)).format(
                "MM-DD-YYYY HH:mm"
              )}
            />
            <KeyValueGrid label="Duration" value={item?.duration} />
            <KeyValueGrid label="Note" value={item?.note?.text} />
          </div>
        );
      })
    ) : (
      <p>There are no records to display</p>
    );
  };

  const handleComplementaryInfo = async () => {
    setIsEnabledComplementaryInfoButton(false);

    const payload = {
      ...complementaryStore,
      patientEngagementRef: patientEngagement?.id,
    };

    try {
      agent.PatientEngagement.complementaryInfo(payload);
      setEditMode(false);
      setTimeout(() => {
        getPatientEngagementDetails();
      }, 1000);
    } catch (err) {
      console.log("error on handle complementary info", err);
    } finally {
      setIsEnabledComplementaryInfoButton(true);
    }
  };

  const ComplementaryInfoGrid = ({ label, value }: any) => {
    return (
      <KeyValueGrid
        label={getComplementaryInfoName(label)}
        value={
          isDate(label, value)
            ? moment
                .utc(new Date(value).toISOString())
                .format("MM-DD-YYYY HH:mm")
            : value
        }
      />
    );
  };

  const flattenModifiedFields = (fields: any) => {
    const flatFields: any = {};
    // console.log(`DEBUG fields: `, fields);

    if (Object.keys(fields).length > 0) {
      for (const [key, value] of Object.entries(fields)) {
        // console.log(`DEBUG ${key}: ${value}`);
        if (isArray(value)) {
          const firstValue = value?.[0] ?? null;
          console.log('DEBUG firstValue: ', firstValue);
          if (firstValue && isObject(firstValue)) {
            for (const [innerKey, innerValue] of Object.entries(firstValue)) {
              if (!isObject(innerValue) && !isArray(innerValue)) {
                flatFields[innerKey] = innerValue;
              }
            }
          }
        } else {
          flatFields[key] = value;
        }
      }
    }

    console.log(`DEBUG flat flatFields: `, flatFields);
    return flatFields;
  };

  const ComplementaryInfoReadMode = ({ data }: any) => {
    const { scope = {} } = data;
    const { checklistItem = {} } = scope;

    return (
      checklistItem &&
      Object.keys(checklistItem).length > 0 &&
      Object.entries(checklistItem).map((item: any) => {
        return (
          <div
            style={{ marginBottom: "40px" }}
            className="complementary-item-container"
          >
            <Typography variant="h5" gutterBottom component="div">
              {getComplementaryInfoName(item?.[0])}
            </Typography>

            {item?.[1] &&
              Object.keys(item?.[1]).length > 0 &&
              Object.entries(item?.[1]).map((elem: any) => {
                return elem?.[0] !== "modifiedFields" ? (
                  <ComplementaryInfoGrid label={elem?.[0]} value={elem?.[1]} />
                ) : elem?.[1] && Object.keys(elem?.[1]).length > 0 ? (
                  Object.entries(flattenModifiedFields(elem?.[1])).map(
                    (i: any) => {
                      return (
                        <ComplementaryInfoGrid label={i?.[0]} value={i?.[1]} />
                      );
                    }
                  )
                ) : null;
              })}
          </div>
        );
      })
    );
  };

  return (
    <Page title="Member Engagement Details">
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h3" component="h1" paragraph>
              Member Engagement Details
            </Typography>
            <Stack spacing={2}>
              <Breadcrumbs separator="&#8226;" aria-label="breadcrumb">
                {breadcrumbs}
              </Breadcrumbs>
            </Stack>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid container direction="row" spacing={3} marginTop={2}>
            <Grid container item md={12}>
              <Workflow
                record={patientEngagement}
                componentMapping={WorkflowComponentMapping}
                workflowHandler={WorkflowHandler}
                workflowData={WorkflowData}
                refreshData={getPatientEngagementDetails}
              />
            </Grid>
            <Grid container item md={4}>
              <KeyValuePaper title="Member Engagement">
                <KeyValueGrid label="Status" value={patientEngagement.status} />
                <KeyValueGrid
                  label="Sub Status"
                  value={patientEngagement.subStatus}
                />
                <KeyValueGrid
                  label="Language"
                  value={patientEngagement.language}
                />

                <Grid container item spacing={1} wrap="nowrap">
                  <Grid
                    container
                    item
                    xs={4}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}
                    justifyContent="flex-end"
                  >
                    <Typography align="right">
                      <b>Assigned To</b>
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    zeroMinWidth
                    sx={{ alignItems: "center" }}
                  >
                    <Tooltip
                      title={
                        patientEngagement?.ownedBy
                          ? `${patientEngagement.ownedBy?.firstName} ${patientEngagement.ownedBy?.middleName} ${patientEngagement.ownedBy?.lastName}`
                          : ""
                      }
                    >
                      <Typography noWrap>
                        {patientEngagement?.ownedBy
                          ? `${patientEngagement.ownedBy?.firstName} ${patientEngagement.ownedBy?.middleName} ${patientEngagement.ownedBy?.lastName}`
                          : ""}
                      </Typography>
                    </Tooltip>

                    <LightTooltip title="Edit Assigned To" placement="bottom">
                      <EditIcon
                        className="font-small margin-xs-right-btn text-primary div-hand"
                        sx={{
                          color: "#1890FF",
                          cursor: "pointer",
                          marginLeft: "8px",
                          fontSize: "1.2rem !important",
                        }}
                        onClick={() => setIsOpenReAssign(true)}
                      />
                    </LightTooltip>
                  </Grid>
                </Grid>

                {/* <KeyValueGrid
                  label="Assigned To"
                  value={
                    patientEngagement.ownedBy
                      ? `${patientEngagement.ownedBy?.firstName} ${patientEngagement.ownedBy?.middleName} ${patientEngagement.ownedBy?.lastName}`
                      : ""
                  }
                /> */}
                <KeyValueGrid label="Opt In Status" value={optInStatus} />
                <Grid
                  container
                  item
                  justifyContent="center"
                  alignItems="center"
                >
                  <KeyValueGrid
                    label="Interview Status"
                    value={assesmentStatus}
                  />
                  <Grid
                    container
                    item
                    justifyContent="center"
                    alignItems="center"
                  ></Grid>
                  {comxEnabled && <Grid item>
                    <Button
                      variant="contained"
                      disabled={!isEnabledEngageButton}
                      onClick={() => engage(patientEngagement)}
                    >
                      Engage
                    </Button>
                  </Grid>}
                </Grid>
              </KeyValuePaper>
            </Grid>
            <Grid container item md={4}>
              <KeyValuePaper title="Member">
                <KeyValueGrid
                  label="Given Name"
                  value={patientEngagement.given_name}
                />
                <KeyValueGrid
                  label="Family Name"
                  value={patientEngagement.family_name}
                />
                <KeyValueGrid
                  label="DOB"
                  value={dateYearFormatWithAge(
                    patientEngagement.dob,
                    "patient"
                  )}
                />
                {!isEmpty(patientSIL) && (
                  <KeyValueGrid
                    label="SIL"
                    value={patientSIL ? patientSIL : ""}
                  />
                )}
              </KeyValuePaper>
            </Grid>
            <Grid container item md={4}>
              {/* <KeyValuePaper title="Contacts"></KeyValuePaper> */}
              <ConfirmMemberInfo
                patientEngagement={patientEngagement}
                isOpen={confirmModalOpen}
                handlerIsOpen={setConfirmModalOpen}
                checklist={checklistData}
                checklistHandler={checklistRefreshHandler}
                recordHandler={recordRefreshHandler}
              />

              <ConfirmGuarantorInfo
                patientEngagement={patientEngagement}
                isOpen={confirmGuarantorModalOpen}
                handlerIsOpen={setConfirmGuarantorModalOpen}
                checklist={checklistData}
                checklistHandler={checklistRefreshHandler}
                recordHandler={recordRefreshHandler}
              />

              <ConfirmNatOptIn
                isOpen={confirmNatOptInModalOpen}
                handlerIsOpen={setConfirmNatOptInModalOpen}
                checklistHandler={checklistRefreshHandler}
                addPatientEngagementChecklistItem={
                  addPatientEngagementChecklistItem
                }
                openDispositon={setDisposition}
              />

              <ScheduleNatSession
                patientEngagement={patientEngagement}
                isOpen={scheduleNatSessionModalOpen}
                handlerIsOpen={setScheduleNatSessionModalOpen}
                checklist={checklistData}
                checklistHandler={checklistRefreshHandler}
                recordHandler={recordRefreshHandler}
              />

              <ConfirmContact
                patientEngagement={patientEngagement}
                fhirPatient={patient}
                isOpen={confirmContactModalOpen}
                handlerIsOpen={setConfirmContactModalOpen}
                checklist={checklistData}
                checklistHandler={checklistRefreshHandler}
                refreshContactAttempt={fetchContactAttempts}
                refreshData={fetchPatient}
                openDispositon={setDisposition}
              />
            </Grid>

            {currentPatientCreateOrder && (
              <CreateOrder
                hideCreateButton
                currentPatient={currentPatientCreateOrder}
                checklist={checklistData}
                handleAfterCreation={(orderUuid: string) => {
                  // const urlredirect: string =
                  //   PATH_DASHBOARD.app.request + "/" + orderUuid;
                  // navigate(urlredirect, { replace: false });
                }}
                createRequest={createRequest}
                handlerCreateRequest={setCreateRequest}
                addPatientEngagementChecklistItem={
                  addPatientEngagementChecklistItem
                }
              />
            )}
            {currentPatientCreateOrder && currentPatientCreateOrder.uuid && (
              <TasksList
                patientId={currentPatientCreateOrder?.uuid}
                isOpen={showTasksList}
                checklistHandler={checklistRefreshHandler}
                handlerIsOpen={(isOpen) => {
                  setShowTasksList(isOpen);
                }}
              />
            )}

            <ConfirmCMOptInItem
              isOpen={confirmOptInItem}
              handlerIsOpen={setConfirmOptInItem}
              addPatientEngagementChecklistItem={
                addPatientEngagementChecklistItem
              }
            />
            <ReviewNatReportItem
              patientEngagement={patientEngagement}
              isOpen={reviewNatReportItem}
              handlerIsOpen={setReviewNatReportItem}
              addPatientEngagementChecklistItem={
                addPatientEngagementChecklistItem
              }
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid container direction="row" spacing={3} marginTop={2}>
            <Grid container item md={4}>
              <KeyValuePaper title="Contact Attempts">
                <ContactAttemptsComp records={contactAttempts} />
                {/* <ContactAttemptTable data={contactAttempts} /> */}
              </KeyValuePaper>
            </Grid>
            <Grid container item md={4}>
              <KeyValuePaper
                title={t(`patient_engagement.complementary_info.title`)}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-start"
                >
                  <Grid
                    item
                    xs={3}
                    sx={{ textAlign: "right", marginBottom: 2 }}
                  >
                    {!editMode ? (
                      <LightTooltip
                        title="Edit Complementary Info"
                        placement="bottom"
                        style={{ marginRight: 15 }}
                      >
                        <EditIcon
                          className="font-small margin-xs-right-btn text-primary div-hand"
                          sx={{ color: "#1890FF", cursor: "pointer" }}
                          onClick={() => {
                            setEditMode(true);
                          }}
                        />
                      </LightTooltip>
                    ) : (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <LightTooltip
                          title="Close Complementary Info"
                          placement="bottom"
                          style={{ marginRight: 15 }}
                        >
                          <ClearIcon
                            className="font-small margin-xs-right-btn text-primary div-hand"
                            sx={{ color: "#1890FF", cursor: "pointer" }}
                            onClick={() => {
                              setEditMode(false);
                            }}
                          />
                        </LightTooltip>
                        <Button
                          variant="contained"
                          disabled={!isEnabledComplementaryInfoButton}
                          onClick={handleComplementaryInfo}
                        >
                          Save
                        </Button>
                      </div>
                    )}
                  </Grid>
                </Grid>

                {editMode ? (
                  <ComplementaryInfoLibrary
                    dataSchema={dataSchema}
                    data={data}
                    saveStore={setComplementaryStore}
                  />
                ) : (
                  <ComplementaryInfoReadMode data={data} />
                )}

                {/* <ComplementaryInfo data={parsePatientEngagementData} /> */}
              </KeyValuePaper>
            </Grid>
            <Grid container item md={8}>
              {patientEngagementId && (
                <NotesList patientEngagementId={patientEngagementId} />
              )}
            </Grid>
          </Grid>
        </Grid>

        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert
              onClose={handleCloseSnackbar}
              severity="success"
              sx={{ width: "100%" }}
            >
              {successMessageSnackbar}
            </Alert>
          </Snackbar>
        </Stack>

        <Dialog
          open={isOpenReAssign}
          onClose={() => setIsOpenReAssign(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="md"
          PaperProps={{
            sx: {
              minHeight: "400px",
            },
          }}
        >
          <DialogTitle id="alert-dialog-title">Re-Assign To</DialogTitle>
          <DialogContent>
            <SelectType
              others={{
                data: allUsers,
                patient: patientEngagement,
                refreshRecordHandler: getPatientEngagementDetails,
                handler: handlerAssignUser,
                checklistItem: {},
              }}
            />
          </DialogContent>
        </Dialog>
      </Container>
    </Page>
  );
}
