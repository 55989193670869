import {
  Button,
  CircularProgress,
  Divider,
  Drawer,
  Grid,
  List,
  ListItem,
  Stack,
} from "@material-ui/core";
import { has } from "lodash";
import { IndexedTasksWithServiceRequestsAndOwner } from "models/fhirQuery";
import { FhirServiceRequestMod } from "models/serviceRequest";
import { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import usePrintableTaskInfo from "../../hooks/usePrintableTaskInfo";
import useServiceRequest from "../../hooks/useServiceRequest";
import useTask from "../../hooks/useTask";
import { getResourceRef, getSplitReference } from "../../utils/fhirUtils";

import agent from "api/agent";

pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.js";

interface RequestRow {
  id: string;
  data: FhirServiceRequestMod.ServiceRequest;
}

interface ReviewNatReportItemProps {
  patientEngagement: any;
  isOpen: boolean;
  handlerIsOpen: Function;
  addPatientEngagementChecklistItem?: any;
}

export default function ReviewNatReportItem({
  patientEngagement,
  isOpen,
  handlerIsOpen,
  addPatientEngagementChecklistItem,
}: ReviewNatReportItemProps) {
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(isOpen);
  const [openLoadingBackdrop, setOpenLoadingBackdrop] = useState<boolean>(
    false
  );
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [reportUrl, setReportUrl] = useState(null);
  const [requestRows, setRequestRows] = useState<RequestRow[]>([]);
  const [serviceRequest, setServiceRequest] = useState(null);
  const [task, setTask] = useState(null);

  const {
    setServiceRequestId,
    fhirServiceRequest,
    fhirPlanDefinition,
    fhirOrganization,
    fhirPractitioner,
    fhirPatient,
    fhirGeneralPractitioner,
    fhirTasks,
  } = useServiceRequest();
  const {
    setFhirTask,
    fhirTask,
    patientTaskOwner,
    relatedPersonTaskOwner,
    fhirMeasureReport,
    indexedfhirQuestionnaires,
    indexedfhirQuestionnaireResponses,
    indexedAnswerMaps,
  } = useTask();

  const printableTaskInfo = usePrintableTaskInfo({
    fhirServiceRequest: fhirServiceRequest,
    fhirPatient: fhirPatient,
    fhirPractitioner: fhirPractitioner,
    fhirOrganization: fhirOrganization,
    fhirGeneralPractitioner: fhirGeneralPractitioner,
    fhirTask: fhirTask,
    fhirPlanDefinition: fhirPlanDefinition,
    beneficiary: patientTaskOwner,
    guarantor: relatedPersonTaskOwner,
    measureReport: fhirMeasureReport,
    indexedFhirQuestionnaires: indexedfhirQuestionnaires,
    indexedfhirQuestionnaireResponses: indexedfhirQuestionnaireResponses,
    indexedAnswerMaps,

    // showMeasureQR: showMeasureQR,
  });

  useEffect(() => {
    setConfirmModalOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (confirmModalOpen) {
      if (patientEngagement) {
        console.log("ReviewNatReportItem patientEngagement", patientEngagement);
        const patientRefUUID = getPatientRefUUID();
        if (patientRefUUID) {
          console.log("ReviewNatReportItem patientRefUUID: ", patientRefUUID);
          getTasksByPatientUuuid(patientRefUUID);
        }
      }
    }
  }, [confirmModalOpen, patientEngagement]);

  useEffect(() => {
    if (reportUrl) {
      console.log("ReviewNatReportItem reportUrl: ", reportUrl);
    }
  }, [reportUrl]);

  useEffect(() => {
    if (serviceRequest && serviceRequest?.id) {
      console.log("ReviewNatReportItem serviceRequest: ", serviceRequest);
      setServiceRequestId(serviceRequest?.id);
    }
  }, [serviceRequest]);

  useEffect(() => {
    if (fhirTasks) {
      if (fhirTasks.length > 0) {
        console.log("ReviewNatReportItem fhirTasks: ", fhirTasks);
        const activeTasks = fhirTasks.filter((t) => t?.status === "completed");
        console.log("ReviewNatReportItem activeTasks: ", activeTasks);
        try {
          activeTasks.sort(function (a, b) {
            return a.authoredOn.localeCompare(b.localeCompare);
          });
        } catch (error) {
          console.log("Could not order tasks by authoredOn");
        }
        setTask(activeTasks[0]);
      }
    }
  }, [fhirTasks]);

  useEffect(() => {
    if (task) {
      setFhirTask(task);
      console.log("ReviewNatReportItem task: ", task);
    }
  }, [task]);

  // useEffect(() => {
  //   if (
  //     printableTaskInfo.questionnaire_response_list &&
  //     printableTaskInfo.questionnaire_response_list.length > 0
  //   ) {
  //     // if (fhirPatient && fhirServiceRequest && fhirTask) {
  //     console.log("ReviewNatReportItem printableTaskInfo: ", printableTaskInfo);
  //     // getPrintedVersion();
  //   }
  //   // }
  // }, [printableTaskInfo.questionnaire_response_list]);

  useEffect(() => {
    if (fhirPatient === undefined) return;

    console.log("ReviewNatReportItem printableTaskInfo: ", printableTaskInfo);

    const msDelay = 3000;
    const handler = setTimeout(() => {
      // added debounce to getPrintedVersion
      console.log("debounce(getPrintedVersion) : callback");
      getPrintedVersion();
    }, msDelay);

    return () => {
      console.log("debounce(getPrintedVersion) : reset delay");
      clearTimeout(handler);
    };
  }, [fhirTask, fhirPatient, patientTaskOwner, printableTaskInfo]);

  const getPatientRefUUID = () => {
    if (patientEngagement && patientEngagement?.fhir_uri) {
      const resRef = getResourceRef(patientEngagement?.fhir_uri);
      console.log("ReviewNatReportItem resRef", resRef);
      if (resRef !== undefined) {
        const splitRef = getSplitReference(resRef);
        if (splitRef.resourceType === "Patient" && splitRef.uuid) {
          return splitRef.uuid;
        }
      }
    }
    return null;
  };

  const getTasksByPatientUuuid = async (patientUuid: string) => {
    setOpenLoadingBackdrop(true);
    const indexedTasksWithServiceRequests: IndexedTasksWithServiceRequestsAndOwner = await agent.FhirTask.byPatientWithServiceRequestAndOwner(
      patientUuid
    );
    console.log(
      "ReviewNatReportItem indexedTasksWithServiceRequests: ",
      indexedTasksWithServiceRequests
    );

    if (has(indexedTasksWithServiceRequests, "serviceRequests")) {
      if (indexedTasksWithServiceRequests.serviceRequests) {
        const serviceRequestsArray = [];
        Object.keys(indexedTasksWithServiceRequests.serviceRequests).forEach(
          (s) => {
            serviceRequestsArray.push(
              indexedTasksWithServiceRequests.serviceRequests[s]
            );
          }
        );
        console.log(
          "ReviewNatReportItem serviceRequestsArray: ",
          serviceRequestsArray
        );
        try {
          serviceRequestsArray.sort(function (a, b) {
            return a.authoredOn.localeCompare(b.localeCompare);
          });
        } catch (error) {
          console.log("Could not order service requests by authoredOn");
        }
        setServiceRequest(serviceRequestsArray[0]);
      }
    }
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const getPrintedVersion = async () => {
    setOpenLoadingBackdrop(true);
    try {
      console.log("printableTaskInfo:");
      console.log(printableTaskInfo);
      const response = await agent.Order.getPrintable(printableTaskInfo);
      const url = window.URL.createObjectURL(
        new Blob([response.data, { type: "application/pdf" }])
      );
      setReportUrl(url);
    } catch (error) {
      console.log(error);
    }
    setOpenLoadingBackdrop(false);
  };

  const openNATReport = (e) => {
    e.preventDefault();
    if (serviceRequest && serviceRequest?.id) {
      const taskURL = `${process.env.REACT_APP_BASE_UI_URL}dashboard/app/request/${serviceRequest.id}`;
      console.log("ReviewNatReportItem serviceRequestURL: ", serviceRequest);
      console.log("ReviewNatReportItem taskURL: ", taskURL);
      window.open(taskURL);
    }
  };

  return (
    <>
      <Drawer
        PaperProps={{
          sx: { width: "60%" },
        }}
        anchor="left"
        open={confirmModalOpen}
        onClose={() => {
          setConfirmModalOpen(false);
          handlerIsOpen(false);
        }}
      >
        {/* <Box
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 2,
          }}
        > */}
        <List component="nav">
          <ListItem>
            <Grid container>
              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  onClick={() => {
                    addPatientEngagementChecklistItem(
                      "REVIEW_NAT_REPORT_ITEM",
                      "HJ report reviewed"
                    );
                    setConfirmModalOpen(false);
                  }}
                >
                  Review HJ Report
                </Button>
                <Button
                  onClick={() => {
                    setConfirmModalOpen(false);
                    handlerIsOpen(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={!serviceRequest?.id}
                  onClick={(e) => {
                    openNATReport(e);
                  }}
                >
                  Open HJ Report
                </Button>
              </Stack>
            </Grid>
          </ListItem>
        </List>
        <Divider sx={{ marginBottom: 2 }} />
        {/* </Box> */}

        <Grid container justifyContent="center" alignItems="center">
          {openLoadingBackdrop && (
            <Grid item>
              <CircularProgress />
            </Grid>
          )}
        </Grid>

        {reportUrl && (
          <>
            <Document
              file={{ url: reportUrl }}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {Array.apply(null, Array(numPages))
                .map((x, i) => i + 1)
                .map((page) => (
                  <Page pageNumber={page} />
                ))}
              {/* <Page pageNumber={pageNumber} /> */}
            </Document>
            <p>
              Page {pageNumber} of {numPages}
            </p>
          </>
        )}

        {/* <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
              position: "absolute",
            }}
            open={openLoadingBackdrop}
          >
            <CircularProgress color="inherit" />
          </Backdrop> */}
      </Drawer>
    </>
  );
}
